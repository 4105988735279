import React, { useState, useEffect } from "react";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Profile.css";
import axiosInstance from "../../../components/axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const Profile = (StudentId) => {
  const [notFound, setNotFound] = useState(0);
  const [formData, setFormData] = useState({
    UserId: StudentId.StudentId,
    email: "",
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Gender: "",
    DateOfBirth: "",
    BirthPlace: "",
    ImageName: "",
    ImageFile: null,
  });

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      ImageFile: e.target.files[0], // Update the property name to ImageFile
    });
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("UserID", StudentId.StudentId);
    form.append("email", formData.email);
    form.append("firstName", formData.FirstName);
    form.append("lastName", formData.LastName);
    form.append("phoneNumber", formData.PhoneNumber);
    form.append("gender", formData.Gender);
    form.append("dateOfBirth", formData.DateOfBirth);
    form.append("birthPlace", formData.BirthPlace);
    form.append("imageName", formData.ImageName);
    form.append("imageFile", formData.ImageFile);
    console.log(notFound);

    if (notFound === 1) {
      setLoading(true);
      console.log("New User");
      try {
        await axiosInstance.post(`/api/PersonalInformations/`, form, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type
          },
        });
        toast.success("Data updated successfully");
        setLoading(false);
      } catch (error) {
        console.log("Error occured", error.message);
        setLoading(false);
        toast.error("Failed to update");
      }
    } else {
      try {
        setLoading(true);
        printFormData(form);

        const putResponse = await axiosInstance.put(
          `/api/PersonalInformations/${StudentId.StudentId}`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type
            },
          }
        );
        if (putResponse.status === 200) {
          setLoading(false);
          // Success

          toast.success("Data updated successfully");
        } else {
          setLoading(false);
          // Display an error message

          toast.error("Failed to update ");
        }
      } catch (error) {
        console.log("Error in Put ", error.message);
      }
    }
    function printFormData(form) {
      for (const [key, value] of form.entries()) {
        console.log(`${key}: ${value}`);
      }
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const getResponse = await axiosInstance.get(
          `/api/PersonalInformations/${StudentId.StudentId}`
        );
        console.log("Profile details for student", getResponse);
        Cookies.set('profileImgURL', getResponse.data.imageName, { expires: 1 / 24 });
        const birthDate = new Date(getResponse.data.dateOfBirth);

        setFormData({
          FirstName: getResponse.data.firstName,
          LastName: getResponse.data.lastName,
          PhoneNumber: getResponse.data.phoneNumber,
          Gender: getResponse.data.gender,
          DateOfBirth: birthDate.toISOString().split("T")[0],
          BirthPlace: getResponse.data.birthPlace,
          ImageName: getResponse.data.imageName,
          email: getResponse.data.email,
        });
        setSelectedImage(getResponse.data.imageName);

        setLoading(false);
      } catch (error) {
        console.log(StudentId.StudentId);
        console.log("catch statement");
        console.log(error);
        setLoading(false);
        if (error.response.status === 404) {
          console.log("INSIDE CATCH ERROR OF IF");
          setNotFound(1);
        } else {
          console.log("Gone in else");
          setNotFound(0);
        }
      }
    };

    fetchProfileDetails();
  }, [StudentId]);

  return (
    <form class="detail-wrapper">
      <div
        className="profile-photo"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
          {" "}
          Setup Personal Details
        </h3>
        <p style={{ margin: "5px 0px 5px 0px" }}>Your Profile Picture</p>
        <div
          className="upload-photo"
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            border: "1px dashed #aaa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderRadius:'50%',
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPhotoFilm}
              style={{ fontSize: "50px", color: "#aaa" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100px",
              height: "100px",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </div>
        <hr />
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>First Name</label>
          <input
            type="text"
            placeholder="Please enter your first name"
            value={formData.FirstName}
            onChange={(e) => handleChange(e, "FirstName")}
          />
        </div>
        <div className="input-fields">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Please enter your last name"
            value={formData.LastName}
            onChange={(e) => handleChange(e, "LastName")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Email</label>
          <input
            type="text"
            placeholder="Please enter your email"
            value={formData.email}
            onChange={(e) => handleChange(e, "email")}
          />
        </div>
        <div className="input-fields">
          <label>Phone number</label>
          <input
            type="text"
            placeholder="+1 |   Please enter your phone number"
            value={formData.PhoneNumber}
            onChange={(e) => handleChange(e, "PhoneNumber")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            value={formData.Gender}
            onChange={(e) => handleChange(e, "Gender")}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="input-fields">
          <label>DOB</label>
          <input
            type="date"
            className="form-input"
            value={formData.DateOfBirth}
            onChange={(e) => handleChange(e, "DateOfBirth")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Place of Birth</label>
          <input
            type="text"
            placeholder="Please enter your Birthplace"
            value={formData.BirthPlace}
            onChange={(e) => handleChange(e, "BirthPlace")}
          />
        </div>
      </div>

      <div className="btn-field">
        <button className="primary-button" onClick={submit}>
          Update profile
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
};

export default Profile;
