import React, { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import Cookies from "js-cookie";
import emptyAnimation from "../../../components/assets/images/Animation - 1717495366390.json"
const StudentDashboardList = (props) => {
  const [loading, setLoading] = useState(false);
  const currentUserId = props.StudentUID;
  const [data , setData] = useState(false);
  const [studentData, setStudentData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      console.log(currentUserId);
      
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/ApplyModels/Student?userId=${currentUserId}`
        );
        setStudentData(response.data);
        setData(true);
        console.log(response);
        console.log(studentData);
      } catch (error) {
        console.log(error);
        setData(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
    {data && (
      <div className="table-wrapper">
      <h1 style={{ fontWeight: "500", color: "blue", marginTop: "10px" }}>
        Applied Universities
      </h1>
      <table className="listTable">
        <thead>
          <tr>
            <th>Applied University</th>
            <th>Type</th>
            <th>Application Fee</th>
            <th>Accomodation Fee</th>
            <th>Tution Fee</th>
            <th>Total Fee</th>
            <th>Payment Status</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {studentData.map((student, index) => (
            <tr key={index}>
              <td>{student.universityName}</td>
              <td>{student.type}</td>
              <td>{student.application}</td>
              <td>{student.accomodation}</td>
              <td>{student.tution}</td>
              <td>{student.total}</td>
              <td>{student.paymentStatus}</td>
              <td>{student.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    )}
      {!data && (
        <div>
          <h3 style={{textAlign:'center' , color:'blue'}}>Not Applied Yet</h3>
          <emptyAnimation/>
        </div>
      )}
    </>
  );
};

export default StudentDashboardList;
