import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLock, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const Settings = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
    email: email || "",
    token: token || "",
  });
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      console.log('fetching')
      const response = await fetch(
        `/api/Authentication/reset-password?token=${token}&email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passwordData),
        }
      );
      setLoading(false);

      if (response.ok) {
        toast.success("Password reset successful!");

        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        const data = await response.json();

        toast.error(`Link has been expired`);
      }
    } catch (error) {
      toast.error("An error occurred during password reset.");
    }
  };
  return (
    <div className="form-wrapper">
      <h2>Reset Your Password</h2>
      <span>Enter your email below to receive password reset link</span>
      <form className="form-values">
        <div className="input-wrapper">
          <label htmlFor="new password">New Password</label>
          <div className="input-container">
            <FontAwesomeIcon icon={faLock} className="icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={passwordData.password}
              onChange={handlePasswordChange}
              placeholder="new-password"
            />
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="icon icon-clickable"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
        <ToastContainer/>
        <div className="input-wrapper">
          <label htmlFor="new password">Confirm Password</label>
          <div className="input-container">
            <FontAwesomeIcon icon={faLock} className="icon" />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              placeholder="confirm password"
            />
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEye : faEyeSlash}
              className="icon icon-clickable"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </div>
        </div>
        <Button style={{ margin: "10px" }} className="primary-button " onClick={handleSubmit}>
          Reset
        </Button>
      </form>

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Restting Password...</p>
        </div>
      )}
    </div>
  );
};

export default Settings;

