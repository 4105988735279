import React from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import './UniversityFilter.css';

const UniversityFilter = ({
  filterOn,
  handleFilter,
  handleResetFilter,
  handleApplyFilter,
  filterData,
  setFilterData,
}) => {
  return (
    <div className="filter-container">
      {!filterOn ? (
        <span onClick={handleFilter}>
          Filters
          <IoSettingsSharp />
        </span>
      ) : (
        <div className="button-collection">
          <button onClick={handleResetFilter} className="secondary-button">
            Reset All
          </button>
          <button onClick={handleFilter} className="secondary-button">
            Cancel
          </button>
          <button onClick={handleApplyFilter} className="primary-button">
            Apply Filters
          </button>
        </div>
      )}
      {filterOn && (
        <div className="filters">
          <div className="filter-section">
            <h3 className="filter-title">Eligibility</h3>
            <div className="filter-options">
              <div className="filter-option">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  value={filterData.name}
                  onChange={(e) => {
                    setFilterData({ ...filterData, name: e.target.value });
                  }}
                />
              </div>
              <div className="filter-option">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  value={filterData.country}
                  onChange={(e) => {
                    setFilterData({ ...filterData, country: e.target.value });
                  }}
                />
              </div>
              <div className="filter-option">
                <label htmlFor="educationLevel">Education Level</label>
                <input
                  type="text"
                  id="educationLevel"
                  value={filterData.educationLevel}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      educationLevel: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="filter-option">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={filterData.state}
                  onChange={(e) => {
                    setFilterData({ ...filterData, state: e.target.value });
                  }}
                />
              </div>
              <div className="filter-option">
                <label htmlFor="gpaScore">GPA Score</label>
                <input
                  type="text"
                  id="gpaScore"
                  value={filterData.gpaScore}
                  onChange={(e) => {
                    setFilterData({ ...filterData, gpaScore: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <h3 className="filter-title">Programs</h3>
            <div className="filter-options">
              <div className="filter-option">
                <label htmlFor="instituteType">Institute type</label>
                <select
                  name="instituteType"
                  id="instituteType"
                  value={filterData.instituteType}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      instituteType: e.target.value,
                    });
                  }}
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="community">Community</option>
                  <option value="technical">Technical</option>
                </select>
              </div>
              <div className="filter-option">
                <label htmlFor="programType">Program type</label>
                <select
                  name="programType"
                  id="programType"
                  value={filterData.programType}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      programType: e.target.value,
                    });
                  }}
                >
                  <option value="degree">Degree</option>
                  <option value="diploma">Diploma</option>
                  <option value="certificate">Certificate</option>
                  <option value="shortCourse">Short Course</option>
                </select>
              </div>
              <div className="checkbox-collection">
                <div className="filter-option">
                  Fall
                  <input
                    type="checkbox"
                    id="fallProgram"
                    value={filterData.fallProgram}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        fallProgram: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div
                  className="filter-option"
                  value={filterData.summerProgram}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      summerProgram: e.target.checked,
                    });
                  }}
                >
                  Summer
                  <input type="checkbox" id="summerProgram" />
                </div>
                <div
                  className="filter-option"
                  value={filterData.springProgram}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      springProgram: e.target.checked,
                    });
                  }}
                >
                  Spring
                  <input type="checkbox" id="springProgram" />
                </div>
              </div>
              <div className="filter-option">
                <label htmlFor="majorProgram">Major</label>
                <input
                  type="text"
                  id="majorProgram"
                  value={filterData.majorProgram}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      majorProgram: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="filter-option">
                <label htmlFor="areaOfStudy">Area of Study</label>
                <input
                  type="text"
                  id="areaOfStudy"
                  value={filterData.areaOfStudy}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      areaOfStudy: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UniversityFilter;
