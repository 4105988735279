import React from "react";

const Privac = () => {
  return (
    <div>
      <h1 className="footer-heading">Privacy Policy</h1>
    </div>
  );
};

export default Privac;
