import React from 'react';
import { IoMdArrowRoundForward } from 'react-icons/io';
import coverImage from '../../../components/assets/images/consultimg.png';

// css imported from SearchNavigation.css  make your own css file if required on same directory

const JoinRightTrails = () => {
  return (
    <section className="dark-blue">
      <div className="width-1256 search-navigation-wrapper">
        <div className="left-section">
          <h2 className="page-quote">
            Join the{' '}
            <span style={{ color: 'var(--hover-color)' }}>biggest family</span>
            {'  '}
            of Counselors
          </h2>
          <p className="page-subquote">
            Find matching programs & top ranking institutions according to your
            student background.
          </p>
          <a href="/agent/register" className="transparent-button">
            Join Us Today <IoMdArrowRoundForward />
          </a>
        </div>
        <div className="right-section">
          <img
            src={coverImage}
            alt="search-navigation"
            className="search-navigation-image"
          />
        </div>
      </div>
    </section>
  );
};

export default JoinRightTrails;
