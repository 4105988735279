import React, { useState } from "react";
import "./Form.css";
import {
  faApple,
  faFacebook,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { faEye, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import { useAuth } from "../../contexts/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const LoginForm = ({ role }) => {
  const { login } = useAuth();
  const isStudent = role === "student";
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = "username is required.";
      }

      if (!values.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await login(values);

        if (response.status === 200) {
          setTimeout(() => {
            setLoading(false);
            navigate("/dashboard");
          });
          toast.success("Login Success");
        } else {
          const data = response.data;
          setMessage(`Login failed: ${data.message}`);
          setLoading(false);
          toast.error("Unexoected error :", data.message);
        }
      } catch (error) {
        toast.error("Invalid Details");
        setMessage("Invalid details.");
        setLoading(false);
      }
    },
  });
  return (
    <>
      <div className="form-wrapper">
        <ToastContainer />
        <h2 className="welcome-text">
          <span style={{ color: "var(--darkblue-color)", fontWeight: "700" }}>
            {isStudent ? "Student" : "Counselor"}
          </span>
          {` `}Login
        </h2>
        <span>Login with Email</span>
        <form onSubmit={formik.handleSubmit} method="get">
          <div className="input-wrapper">
            <label htmlFor="username">Username</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="icon" />
              <input
                type="text"
                value={formik.values.username}
                onChange={formik.handleChange}
                autoComplete="off"
                name="username"
                id="username"
                placeholder="Username"
              />
            </div>
          </div>
          {formik.touched.username && formik.errors.username && (
            <div className="error">{formik.errors.username}</div>
          )}
          <div className="input-wrapper">
            <label htmlFor="password">Password</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="icon" />
              <input
                type={showpassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                autoComplete="off"
                name="password"
                id="password"
                placeholder="Password"
              />
              <FontAwesomeIcon
                icon={faEye}
                className="icon icon-clickable"
                onClick={() => setShowPassword(!showpassword)}
              />
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="error">{formik.errors.password}</div>
          )}

          <p className="forget-password">
            <a
              href={
                isStudent ? "member/forget-password" : "agent/forget-password"
              }
            >
              Forget Password ?
            </a>
          </p>
          <Button style={{ margin: "10px" }} className="primary-button">
            Login
          </Button>
        </form>
        <div className="alternative-methods">
          <span className="or-pop">OR</span>
          <div className="social-icons">
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            <FontAwesomeIcon icon={faGoogle} className="social-icon" />
            <FontAwesomeIcon icon={faApple} className="social-icon" />
          </div>
        </div>
        <p style={{ marginTop: " 20px" }}>
          Don't have an account?{` `}
          {
            <a
              style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
              href={isStudent ? "/member/register" : "/agent/register"}
            >
              Register Now
            </a>
          }
        </p>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Logging in.....</p>
        </div>
      )}
    </>
  );
};

export default LoginForm;
