import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/Auth/AuthContext";
import { axiosInstance } from "../../../api";
import "./Documents.css";
import DocumentCard from "../../../components/Cards/DocumentCard";
import { toast } from "react-toastify";

const Documents = (StudentId) => {
  const { currentUser } = useAuth();
  const [filePreviews, setFilePreviews] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteData, setDeleteData] = useState({ id: null, fileType: null });
  const [progress, setProgress] = useState(0);

  const [formData, setFormData] = useState({
    userId: StudentId.StudentId,
    passportFile: [],
    marksheetsFile: [],
    recommendationsFile: [],
    financialDocumentsFile: [],
    othersFile: [],
    resumeFile: [],
    englishLanguageFile: [],
  });

  const ProgressBar = ({ progress }) => {
    return (
      <div className="progress-container">
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="progress-text">{progress}%</div>
      </div>
    );
  };
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await axiosInstance.get(
          `/api/DocumentsModels/${StudentId.StudentId}`
        );
        setFilePreviews(data);
      } catch (error) {
        console.log(error);
        setFilePreviews({});
      }
    };
    fetchDocuments();
  }, [StudentId.StudentId, loading]);

  const handleDelete = (id, fileType) => {
    setShowModal(true);
    setDeleteData({ id, fileType });
  };

  const confirmDelete = () => {
    const { id, fileType } = deleteData;
    setLoading(true);
    const fileKey = {
      passportFile: "passport",
      marksheetsFile: "marksheets",
      recommendationsFile: "recommendation",
      financialDocumentsFile: "financial",
      othersFile: "other",
      resumeFile: "resume",
      englishLanguageFile: "englishlanguage",
    }[fileType];

    axiosInstance
      .delete(`/api/DocumentsModels/${id}/${fileKey}`)
      .then(() => {
        setLoading(false);
        toast.success("Document deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
        toast.error("An error occurred while deleting document");
      });

    setShowModal(false);
    setDeleteData({ id: null, fileType: null });
  };

  // remove file from preview before uploading
  const handleRemove = (id, index) => {
    setFormData((prevFormData) => {
      const newFiles = prevFormData[id].filter((_, i) => i !== index);
      return { ...prevFormData, [id]: newFiles };
    });

    setFilePreviews((prevPreviews) => {
      const newPreviews = prevPreviews[id].filter((_, i) => i !== index);
      return { ...prevPreviews, [id]: newPreviews };
    });
  };

  const handleFileChange = (e, id, files = null) => {
    const selectedFiles = files || Array.from(e.target.files);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: prevFormData[id].concat(selectedFiles),
    }));

    setFilePreviews((prevPreviews) => ({
      ...prevPreviews,
      [id]: (prevPreviews[id] || []).concat(
        selectedFiles.map((file) => ({
          name: file.name,
          type: file.type,
          url: URL.createObjectURL(file),
        }))
      ),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    setProgress(0);
    const payLoad = new FormData();

    payLoad.append("userId", StudentId.StudentId);

    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((file) => {
          payLoad.append(key, file);
        });
      } else if (formData[key]) {
        payLoad.append(key, formData[key]);
      }
    });

    try {
      await axiosInstance.patch("/api/DocumentsModels", payLoad, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(
            `Loaded: ${loaded}, Total: ${total}, Progress: ${percent}%`
          );
          console.log("check");
          setProgress(percent);
          console.log(ProgressEvent);
        },
      });
      setLoading(false);
      toast.success("Documents updated successfully");
      setFormData({
        userId: StudentId.StudentId,
        passportFile: [],
        marksheetsFile: [],
        recommendationsFile: [],
        financialDocumentsFile: [],
        othersFile: [],
        resumeFile: [],
        englishLanguageFile: [],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      toast.error("An error occurred while updating documents");
    }
  };
  const documentCardsData = [
    {
      title: "Passport File (Choose Multiple) *",
      fileType: "passportFile",
      previews: filePreviews.passportFile,
      lists: filePreviews.passportList,
    },
    {
      title: "English Language Proficiency Certificate",
      fileType: "englishLanguageFile",
      previews: filePreviews.englishLanguageFile,
      lists: filePreviews.englishLanguageList,
    },
    {
      title: "Marksheets File (Choose Multiple)",
      fileType: "marksheetsFile",
      previews: filePreviews.marksheetsFile,
      lists: filePreviews.marksheetsList,
    },
    {
      title: "CV/Resume",
      fileType: "resumeFile",
      previews: filePreviews.resumeFile,
      lists: filePreviews.resumeList,
    },
    {
      title: "Recommendation File (Choose Multiple)",
      fileType: "recommendationsFile",
      previews: filePreviews.recommendationsFile,
      lists: filePreviews.recommendationsList,
    },
    {
      title: "Financial Document (Choose Multiple)",
      fileType: "financialDocumentsFile",
      previews: filePreviews.financialDocumentsFile,
      lists: filePreviews.financialDocuments,
    },
    {
      title: "Others Files (Choose Multiple)",
      fileType: "othersFile",
      previews: filePreviews.othersFile,
      lists: filePreviews.otherList,
    },
  ];

  return (
    <>
      <section className="documentWrapper">
        <h2>
          Professional{" "}
          <span style={{ color: "var(--darkblue-color)" }}>Information</span>
        </h2>
        <form className="documentContainer">
          <div
            style={{
              display: "flex",
              gap: "10px ",
              padding: "20px 5px",
              width: "100%",
              justifyContent: "flex-end",
            }}
            className="button-container"
          >
            {/* <button className="secondary-button" type="button">
              Discard
            </button> */}
            <button className="primary-button" onClick={handleSave}>
              Save
            </button>
          </div>
          {documentCardsData.map((data, index) => (
            <DocumentCard
              key={index}
              title={data.title}
              fileType={data.fileType}
              previews={data.previews}
              lists={data.lists}
              onChange={handleFileChange}
              onDelete={handleDelete}
              onRemove={handleRemove}
            />
          ))}
        </form>
      </section>
      {/* {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Updating your documents, please wait...</p>
        </div>
      )} */}
      {loading && (
        <div className="loading-overlay">
          <ProgressBar progress={progress} />
        </div>
      )}
      {showModal && (
        <div className="loading-overlay">
          <div className="modal-container">
            <span
              style={{ cursor: "pointer", fontSize: "32px" }}
              className="close"
              onClick={() => setShowModal(false)}
            >
              &times;
            </span>
            <div className="modal-content">
              <p>Are you sure to delete this document?</p>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  type="button"
                  className="delete-button"
                  onClick={confirmDelete}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="primary-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Documents;
