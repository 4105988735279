import React from 'react';
import './ReviewSection.css';
import { Carousel, WideCarousel } from '../../../components';

const ReviewSection = () => {
  const reviews = [
    {
      id: 1,
      image:
        'https://media.istockphoto.com/id/469532554/photo/bahakapur-nepal.jpg?s=1024x1024&w=is&k=20&c=QJcVF-Km9v3-NIKTKtpNNsWBTPXiarqU02wh5OZo-ac=',
      title: 'Title 1',
    },
    {
      id: 2,
      image:
        'https://media.istockphoto.com/id/637696304/photo/patan.jpg?s=612x612&w=0&k=20&c=-53aSTGBGoOOqX5aoC3Hs1jhZ527v3Id_xOawHHVPpg=',
      title: 'Title 2',
    },
    {
      id: 3,
      image:
        'https://media.istockphoto.com/id/521420468/photo/boudhanath-stupa-kathmandu-nepal.jpg?s=612x612&w=0&k=20&c=yyojtAJHXfna8tBMrxYt9rOFasWjKNvLRfb38yYDR0A=',
      title: 'Title 3',
    },

    {
      id: 4,
      image:
        'https://media.istockphoto.com/id/469532554/photo/bahakapur-nepal.jpg?s=1024x1024&w=is&k=20&c=QJcVF-Km9v3-NIKTKtpNNsWBTPXiarqU02wh5OZo-ac=',
      title: 'Title 1',
    },
    {
      id: 5,
      image:
        'https://media.istockphoto.com/id/637696304/photo/patan.jpg?s=612x612&w=0&k=20&c=-53aSTGBGoOOqX5aoC3Hs1jhZ527v3Id_xOawHHVPpg=',
      title: 'Title 2',
    },
    {
      id: 6,
      image:
        'https://media.istockphoto.com/id/521420468/photo/boudhanath-stupa-kathmandu-nepal.jpg?s=612x612&w=0&k=20&c=yyojtAJHXfna8tBMrxYt9rOFasWjKNvLRfb38yYDR0A=',
      title: 'Title 3',
    },

    {
      id: 7,
      image:
        'https://media.istockphoto.com/id/469532554/photo/bahakapur-nepal.jpg?s=1024x1024&w=is&k=20&c=QJcVF-Km9v3-NIKTKtpNNsWBTPXiarqU02wh5OZo-ac=',
      title: 'Title 1',
    },
    {
      id: 8,
      image:
        'https://media.istockphoto.com/id/637696304/photo/patan.jpg?s=612x612&w=0&k=20&c=-53aSTGBGoOOqX5aoC3Hs1jhZ527v3Id_xOawHHVPpg=',
      title: 'Title 2',
    },
    {
      id: 9,
      image:
        'https://media.istockphoto.com/id/521420468/photo/boudhanath-stupa-kathmandu-nepal.jpg?s=612x612&w=0&k=20&c=yyojtAJHXfna8tBMrxYt9rOFasWjKNvLRfb38yYDR0A=',
      title: 'Title 3',
    },
  ];

  const universities = [
    {
      id: 1,
      image:
        'https://1000logos.net/wp-content/uploads/2022/07/University-of-Princeton-Logo.png',
      title: 'Title 1',
    },
    {
      id: 2,
      image:
        'https://1000logos.net/wp-content/uploads/2022/04/American-Eagles-Logo.png',
      title: 'Title 2',
    },
    {
      id: 3,
      image:
        'https://www.mladiinfo.eu/wp-content/uploads/2013/11/princeton-logo.jpg?_gl=1*1glkuy8*_ga*MTcwMDU1NDU4Mi4xNzA5NTUwMzgz*_ga_5D91C4KSD4*MTcwOTU1MDM4My4xLjAuMTcwOTU1MDM4My42MC4wLjA.',
      title: 'Title 3',
    },

    {
      id: 4,
      image:
        'https://1000logos.net/wp-content/uploads/2022/07/University-of-Princeton-Logo.png',
      title: 'Title 1',
    },
    {
      id: 5,
      image:
        'https://1000logos.net/wp-content/uploads/2022/04/American-Eagles-Logo.png',
      title: 'Title 2',
    },
    {
      id: 6,
      image:
        'https://www.mladiinfo.eu/wp-content/uploads/2013/11/princeton-logo.jpg?_gl=1*1glkuy8*_ga*MTcwMDU1NDU4Mi4xNzA5NTUwMzgz*_ga_5D91C4KSD4*MTcwOTU1MDM4My4xLjAuMTcwOTU1MDM4My42MC4wLjA.',
      title: 'Title 3',
    },

    {
      id: 7,
      image:
        'https://1000logos.net/wp-content/uploads/2022/07/University-of-Princeton-Logo.png',
      title: 'Title 1',
    },
    {
      id: 8,
      image:
        'https://1000logos.net/wp-content/uploads/2022/04/American-Eagles-Logo.png',
      title: 'Title 2',
    },
    {
      id: 9,
      image:
        'https://www.mladiinfo.eu/wp-content/uploads/2013/11/princeton-logo.jpg?_gl=1*1glkuy8*_ga*MTcwMDU1NDU4Mi4xNzA5NTUwMzgz*_ga_5D91C4KSD4*MTcwOTU1MDM4My4xLjAuMTcwOTU1MDM4My42MC4wLjA.',
      title: 'Title 3',
    },
  ];

  return (
    <section className="reviewContainer">
      <div className="reviewContent width-1256">
        <h2 className="reviewTitle">
          Voices of Success: Our Students Share Their
          <br /> Experiences
        </h2>
        <p className="reviewSubtitle">
          Students of Right Trail Share Their Inspiring Journeys. Your Path to
          <br />
          Success, Illuminated by Their Stories.
        </p>
        <Carousel items={reviews} />
        <div className="Partner-universities">
          <h2 className="reviewTitle">Our Partner Universities</h2>
          <WideCarousel items={universities} />
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
