import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/Auth/AuthContext";
import { ListTable } from "../../../../components";
import { axiosInstance } from "../../../../api";
import { Button } from "reactstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faUser,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

const UniversityList = () => {
  const { currentUser } = useAuth();
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const isUserAdmin = currentUser.userRole === "Admin";
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = "Username is required.";
      }

      if (!values.email) {
        errors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors.email = "Invalid email address.";
      }

      if (!values.password) {
        errors.password = "Password is required.";
      } else if (
        !/(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          values.password
        )
      ) {
        errors.password = "Invalid format.";
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match.";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await fetch(
          `api/Authentication/register?role=university`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        console.log("Getting inside post API");

        resetForm({
          values: {
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
          },
        });
        if (response.ok) {
          setLoading(false);
          toast.success(
            "Registration successful! Check your email for confirmation."
          );
          setShowForm(false);
        } else {
          const data = await response.json();
          setLoading(false);
          toast.error(`Registration failed: ${data.message}`);
          setShowForm(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred during registration.");
        console.error(error);
        setShowForm(false);
      }
    },
  });
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/Authentication/userlist/university`
        );
        setUniversities(response.data);
        setLoading(false);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    setLoading(false);

    fetchUniversities();
  }, [isUserAdmin]);

  return (
    <section className="info-wrapper">
      <div
        className="flex-elements"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <h2>
          <span style={{ color: "var(--darkblue-color)" }}>University</span>
          {` `}List
        </h2>
        <button className="primary-button" onClick={toggleForm}>
          Add University
        </button>
      </div>
      {loading ? <div>Loading ...</div> : <ListTable items={universities} role='University' />}
      {showForm && (
        <div className={`${showForm ? "popup-modal" : ""}`}>
          <div className="form-wrapper popup-form">
            <h2 className="welcome-text">
              <span
                style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
              >
                Institute
              </span>{" "}
              Registration
            </h2>
            <span>
              Register{" "}
              <span
                style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
              >
                university{" "}
              </span>{" "}
              account
            </span>
            <form className="form-values" onSubmit={formik.handleSubmit}>
              <div className="input-wrapper">
                <label htmlFor="username">Username</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faUser} className="icon" />
                  <input
                    type="text"
                    {...formik.getFieldProps("username")}
                    autoComplete="off"
                    placeholder="Username"
                  />
                </div>
                {formik.touched.username && formik.errors.username && (
                  <div className="error">{formik.errors.username}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="email">Email</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <input
                    type="email"
                    {...formik.getFieldProps("email")}
                    autoComplete="off"
                    placeholder="Email"
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="password">Password</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faLock} className="icon" />
                  <input
                    type={showPassword ? "text" : "password"}
                    {...formik.getFieldProps("password")}
                    autoComplete="off"
                    placeholder="Password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="icon icon-clickable"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="error">{formik.errors.password}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faLock} className="icon" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    {...formik.getFieldProps("confirmPassword")}
                    autoComplete="off"
                    placeholder="Confirm Password"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    className="icon icon-clickable"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <div className="error">{formik.errors.confirmPassword}</div>
                  )}
              </div>
              <Button
                style={{ margin: "10px" }}
                onClick={toggleForm}
                className="secondary-button "
              >
                Cancel
              </Button>
              <Button
                style={{ margin: "10px" }}
                className="primary-button "
                onSubmit={formik.handleSubmit}
              >
                Register
              </Button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default UniversityList;
