import React, { useEffect, useState } from 'react';
import './SimilarUniversity.css';
import uniImage from '../../../components/assets/images/unibg2.png';
import { axiosInstance } from '../../../api';
import { useNavigate } from 'react-router-dom';

const SimilarUniversity = ({ uniId }) => {
  const [similarUniversities, setSimilarUniversities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSimilarUniversities = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/Search/recommend?userId=${uniId}`
        );
        setSimilarUniversities(response.data);
        console.log(similarUniversities);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSimilarUniversities();
  }, [uniId]);

  const handleUniversityClick = (university) => {
    const formattedName = university?.slug
      ? university.slug
      : university.name.replace(/\s+/g, '-');
    navigate(`/university/detail/${formattedName}`, {
      state: {
        university: university,
      },
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <section className="similar-universities-wrapper">
      {similarUniversities.length > 0 && (
        <div className="width-1256">
          <h2 className="similar-university-title">
            Similar{' '}
            <span style={{ color: 'var(--darkblue-color)' }}>Universities</span>
          </h2>
          <div className="similar-universities">
            {similarUniversities.map((university) => (
              <div className="similarUniversityCard" key={university.userId}>
                {university.coverImage ? (
                  <img src={university.coverImage} alt={university.name} />
                ) : (
                  <img src={uniImage} alt={university.name} />
                )}
                <div
                  className="similarUniversityCard__content"
                  onClick={() => handleUniversityClick(university)}
                >
                  <h3>{university.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default SimilarUniversity;
