import React from 'react';
import './VideoParallax.css';

const VideoParallax = () => {
  return (
    <div className="videoWrapper">
      <video src="/assets/videos/landing_video.mp4" autoPlay loop muted />
    </div>
  );
};

export default VideoParallax;
