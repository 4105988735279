import React, { useEffect, useState } from 'react';
import './BlogPage.css';
import { FaXTwitter } from 'react-icons/fa6';
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa';
import blogImage from '../../components/assets/images/aboutlast.png';
import { LuMessagesSquare } from 'react-icons/lu';
import { BlogCard } from '../../components';
import { axiosInstance } from '../../api';

const BlogPage = () => {
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState({
    currentPage: 1,
    pageSize: 8,
  });
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/BlogsModels/`);
        // const response = await axiosInstance.get(
        //   `/api/BlogsModels/Pagination?page=${pagination.currentPage}${
        //     pagination.pageSize ? `&pageSize=${pagination.pageSize}` : ''
        //   }`
        // );
        setBlogData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination.currentPage, pagination.pageSize]);
  function handlePage(type) {
    setLoading(true);
    setpagination((prevPagination) => {
      const newPage =
        type === 'next'
          ? prevPagination.currentPage + 1
          : prevPagination.currentPage - 1;

      //   fetchData({ currentPage: newPage });
      setLoading(false);
      return {
        ...prevPagination,
        currentPage: newPage,
      };
    });
  }

  return (
    <>
      <div className="blogSectionTitle">
        <p>Articles</p>
        <h2>
          Our <span style={{ color: 'var(--darkblue-color)' }}>Blog</span>
        </h2>
      </div>
      <section className="width-1256 blog-wrapper">
        <div className="blogContainer">
          {loading ? (
            <p>Loading...</p>
          ) : (
            blogData.map((item) => <BlogCard key={item.id} {...item} />)
          )}
          {/* <div className="pagination">
            <button
              onClick={() => handlePage('previous')}
              disabled={blogData.currentPage === 1}
            >
              Previous
            </button>
            <span>{`Page ${blogData.currentPage} of ${blogData.totalPages}`}</span>

            <button
              onClick={() => handlePage('next')}
              disabled={
                blogData.currentPage === blogData.totalPages ||
                !blogData.totalPages
              }
            >
              Next
            </button>
          </div> */}
        </div>
        <div className="blog-right-section">
          <div className="feature-post-container">
            <h2>
              <span style={{ color: 'var(--darkblue-color)' }}>Feature</span>
              {` `}posts
            </h2>
            <div className="feature-post">
              <div className="feature-post-card">
                <img src={blogImage} alt="feature-post" />
                <div className="post-details">
                  <p>April 20, 2021</p>
                  <h3>Post Title</h3>
                </div>
              </div>
              <div className="feature-post-card">
                <img src={blogImage} alt="feature-post" />
                <div className="post-details">
                  <p>April 20, 2021</p>
                  <h3>Post Title</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="social-media">
            <h2>
              Social{' '}
              <span style={{ color: 'var(--darkblue-color)' }}>Media</span>
            </h2>
            <div className="social-links">
              <a href="/" className="social-link">
                <FaFacebook size={32} />
              </a>
              <a href="/" className="social-link">
                <FaInstagramSquare size={32} />
              </a>
              <a href="/" className="social-link">
                <FaXTwitter size={32} />
              </a>
              <a href="/" className="social-link">
                <FaYoutube size={32} />
              </a>
              <a href="/" className="social-link">
                <FaLinkedin size={32} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="message-popup-container">
        <div className="message-popup">
          <LuMessagesSquare size={28} />
        </div>
      </div>
    </>
  );
};

export default BlogPage;
