import React from 'react';
import { TbFileUpload } from 'react-icons/tb';
import DocumentPreview from '../Documents/DocumentPreview';

const DocumentCard = ({
  title,
  fileType,
  previews,
  lists,
  onChange,
  onDelete,
  onRemove,
}) => {
  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    onChange(null, fileType, files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="documentCard">
      <h4>{title}</h4>
      <DocumentPreview
        previews={previews}
        lists={lists}
        onDelete={onDelete}
        fileType={fileType}
        onRemove={onRemove}
      />
      <div
        className="document-upload"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type="file"
          id={fileType}
          multiple
          onChange={(e) => onChange(e, fileType)}
        />
        <label htmlFor={fileType}>
          <TbFileUpload size={24} />
          <h4>Drop file here or click to Upload</h4>
        </label>
      </div>
    </div>
  );
};

export default DocumentCard;
