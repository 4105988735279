import React, { useState, useEffect } from "react";
import { ListTable } from "../../../../components";
import { axiosInstance } from "../../../../api";
import { useAuth } from "../../../../contexts/Auth/AuthContext";

const CounselorList = () => {
  const { currentUser } = useAuth();
  const [role , setRole]= useState('Counselor')
  const [loading, setLoading] = useState(true);
  const [counselor, setCounselor] = useState([]);
  const isUserAdmin = currentUser.userRole === "Admin";
  useEffect(() => {
    const getStudent = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          ` /api/Authentication/userlist/counselor`
        );
        const detailedCounselors = await Promise.all(
          response.data.map(async (student) => {
            try {
              const studentInfo = await axiosInstance.get(
                `/api/PersonalInformations/${student.studentUid}`
              );
              return { ...student, ...studentInfo.data };
            } catch (error) {
              console.error(
                `Error fetching info for counselor ${student.studentUid}:`,
                error
              );
              return student;
            }
          })
        );

        setCounselor(detailedCounselors);
        setLoading(false);
        console.log("Detailed student list:", detailedCounselors);
        console.log(counselor);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    getStudent();
  }, [isUserAdmin]);

  return (
    <section className="info-wrapper">
      <h2>
        <span style={{ color: "var(--darkblue-color)" }}>Counselor</span>
        {` `}List
      </h2>
      {loading ? <div>Loading ...</div> : <ListTable items={counselor} role = "Counselor" />}
    </section>
  );
};

export default CounselorList;
