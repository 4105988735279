import React from "react";
import "./WelcomeSection.css";

const WelcomeSection = () => {
  return (
    <section className="welcomeContainer width-1256">
      <div className="content">
        <h1>
          College and Career <br /> Starts Here
        </h1>
        <h4>
          Get Matched With <br />
          Your Personalized Programs Today
        </h4>
        <a href="/student" className="primary-button">
          I am a student exploring studying abroad
        </a>
        <div className="buttonContainer">
          <a href="/institution" className="transparent-button">
            I work at school
          </a>
          <a href="/recruitment-partner" className="transparent-button">
            I am a recruitment partner
          </a>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
