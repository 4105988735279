import React, { useEffect, useState } from 'react';
import './UniversityTab.css';
import { axiosInstance } from '../../../api';
import { CourseCard, FinanceCard } from '../../../components';

const UniversityTab = ({ university }) => {
  const [activeTab, setActiveTab] = useState('About');
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState([]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (activeTab === 'About') return;
        const response = await axiosInstance.get(`/api/${activeTab}Models/`);
        const universityData = response.data.filter(
          (data) => data.userId === university.userId
        );
        setTabData(universityData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [activeTab, university.userId]);

  const tabContents = {
    About: (
      <div className="tab-content">
        <h2 style={{ color: 'var(--darkblue-color)' }}>
          About {university.name}
        </h2>
        <p style={{ textAlign: 'justify', color: '#333', fontWeight: '500' }}>
          {university.about}
        </p>
      </div>
    ),
    Gallery: (
      <div className="tab-content">
        <h2>Gallery</h2>
        {loading ? (
          <p>Loading..</p>
        ) : (
          <div className="content-container">
            {tabData.length === 0 && <p>No gallery items available</p>}
            {tabData.map((item, index) => (
              <div className="gallery-item" key={index}>
                <img
                  src={item.imageLink}
                  alt="gallery"
                  className="gallery-image"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    ),
    Courses: (
      <div className="tab-content">
        <h2>
          Course <span style={{ color: 'var(--darkblue-color)' }}>Offered</span>
        </h2>
        {loading ? (
          <p>Loading..</p>
        ) : (
          <div className="content-container">
            <CourseCard items={tabData} />
          </div>
        )}
      </div>
    ),
    Finance: (
      <div className="tab-content">
        <h2>Finance</h2>
        {loading ? (
          <p>Loading..</p>
        ) : (
          <div className="content-container">
            <FinanceCard items={tabData} />
          </div>
        )}
      </div>
    ),
    Requirements: (
      <div className="tab-content">
        <h2>Requirements</h2>
        {loading ? (
          <p>Loading..</p>
        ) : (
          <div className="content-container">
            <FinanceCard items={tabData} isReq={true} />
          </div>
        )}
      </div>
    ),
  };

  return (
    <section className="university-tab-section">
      <nav className="width-1256">
        <ul className="university-tabs">
          {Object.keys(tabContents).map((tabName) => (
            <li
              className={`tab ${activeTab === tabName ? 'active' : ''}`}
              key={tabName}
            >
              <span onClick={() => handleTabChange(tabName)}>{tabName}</span>
            </li>
          ))}
        </ul>
      </nav>
      <div className="university-tab-content width-1256">
        {tabContents[activeTab]}
      </div>
    </section>
  );
};

export default UniversityTab;
