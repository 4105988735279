import React from 'react';
import './DashBoardCard.css';
const DashBoardCard = ({ title, count, linkTo }) => {
  return (
    <div
      className="card"
      onClick={() => {
        window.location.href = linkTo;
      }}
    >
      <h2
        style={{
          color: 'var(--darkblue-color)',
          fontSize: '30px',
        }}
      >
        {count}
      </h2>
      <h3>{title}</h3>
    </div>
  );
};

export default DashBoardCard;
