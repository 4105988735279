import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWarehouse,
  faBookOpen,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./UniversityDetails.css";
import { UniverisityDetailsCard } from "../../../components";

const UniversityDetails = () => {
  const [cardsDetails, setCardsDetails] = useState([
    {
      title: "University",
      content:
        `Discover and Admit Top Talent Effortlessly!
        Right Trails brings you closer to prospective students who are eager to join your academic community. Review applications, manage admissions, and connect with both students and counselors efficiently. Enhance your enrollment process and attract the best candidates with ease.`,
      linkTo: "/institution",
      selected: false,
      icon: faWarehouse,
    },
    {
      title: "Students",
      content:
        `Embark on Your Academic Journey with Ease!
        Right Trails simplifies your university application process, connecting you directly with your dream institutions. Manage your applications, track your status, and secure your future, all in one place. Start your journey to higher education today — your future is just a click away!`,
      linkTo: "/student",
      selected: false,
      icon: faBookOpen,
    },
    {
      title: "Partners",
      content:
        `Empower Your Students Towards Higher Education Success!
        With Right Trails, guide your students seamlessly through their university applications. Add students to your dashboard, oversee their application progress, and provide the support they need to succeed. Join us to streamline the path from aspiration to admission.`,
      linkTo: "/recruitment-partner",
      selected: false,
      icon: faUserPlus,
    },
  ]);

  const handleMouseEnter = (index) => {
    const newCards = cardsDetails.map((card, idx) => ({
      ...card,
      selected: idx === index,
    }));
    setCardsDetails(newCards);
  };

  const handleMouseLeave = (index) => {
    const newCards = cardsDetails.map((card, idx) => ({
      ...card,
      selected: false,
    }));
    setCardsDetails(newCards);
  };

  const cards = cardsDetails.map((card, index) => (
    <UniverisityDetailsCard
      key={index}
      title={card.title}
      content={card.content}
      linkTo={card.linkTo}
      selected={card.selected}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <FontAwesomeIcon icon={card.icon} size="2x" color="#3255D9" />
    </UniverisityDetailsCard>
  ));

  return (
    <section className="universityDetailsContainer width-1256">
      <h2>
        Discover Your Dream{" "}
        <span style={{ color: "var(--darkblue-color)" }}>University</span>
      </h2>
      <div className="cards">{cards}</div>
    </section>
  );
};

export default UniversityDetails;
