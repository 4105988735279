import React, { useEffect, useState } from 'react';
import './UniversityDetails.css';
import {
  SimilarUniversity,
  UniversityMap,
  UniversityStatus,
  UniversityTab,
} from '../../sections';
import uniImage from '../../../src/components/assets/images/unibg2.png';
import { useLocation } from 'react-router-dom';
import { axiosInstance } from '../../api';

const UniversityDetails = () => {
  const location = useLocation();
  const initialUniversityState = location.state
    ? location.state.university
    : {};
  const [university, setUniversity] = useState(initialUniversityState);
  const [loading, setLoading] = useState(!initialUniversityState.profileImage);

  useEffect(() => {
    if (!university.profileImage) {
      fetchUniversity(university.userId);
    }
  }, [university.userId]);

  const fetchUniversity = async (userId) => {
    try {
      const response = await axiosInstance.get(`/api/CoreModels/${userId}`);
      setUniversity(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.university) {
      setUniversity(location.state.university);
      if (!location.state.university.profileImage) {
        fetchUniversity(location.state.university.userId);
      }
    }
  }, [location.state]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <>
      <div className="university-navigation">
        <div className="navigation-university width-1256">
          <ul
            style={{ display: 'flex', listStyle: 'none', margin: '10px 5px' }}
          >
            <li>
              <a href="university/search">University</a>
            </li>
            <li className="separator"></li>
            <li>
              <span className="same-uni">{university.name}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="info-container">
        <div className="parallax">
          {university.coverImage ? (
            <img src={university.coverImage} alt="cover" />
          ) : (
            <img src={uniImage} alt="university" />
          )}
        </div>
        <div className="uni-basic-intro">
          {university.profileImage ? (
            <img
              src={university.profileImage}
              className="university-logo"
              alt="university logo"
            />
          ) : (
            <img
              src={uniImage}
              className="university-logo"
              alt="university logo"
            />
          )}
          {/* IF you want to show texts change the margin of uni-basic-intro to 50px 0 */}
          {/* <div className="texts">
            <h1 className="university-name">University of Lagos</h1>
            <p className="university-location">
              <FaLocationDot />
              {` `} Lagos, Nigeria
            </p>
          </div> */}
        </div>
      </div>
      <UniversityStatus status={university} />
      <UniversityTab university={university} />
      <UniversityMap university={university} />
      <SimilarUniversity uniId={university.userId} />
    </>
  );
};

export default UniversityDetails;
