import axios from 'axios';
import Cookies from "js-cookie";
// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: '',
});


// Set the 'Authorization' header with the token
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
export default axiosInstance;
