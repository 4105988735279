import React from "react";
import { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";

const UniversityGlance = (UniversityId) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    userId: UniversityId.UniversityId,
    description: "",
    videoLink: "",
  });

  const [notFoundFlag, setNotFoundFlag] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/GlanceModels/${UniversityId.UniversityId}`
        );
        console.log(response);
        setFormData({
          description: response.data.description,
          videoLink: response.data.videoLink,
        });
        setNotFoundFlag(0);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Set the notFoundFlag to 1 if it's a 404 error
          console.log("New user");
          setNotFoundFlag(1);
        } else {
          // Handle other errors
          console.log("already exist user");
          setNotFoundFlag(0);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [UniversityId.UniversityId]);

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("userId", UniversityId.UniversityId);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("videoLink", formData.videoLink);

    console.log(formDataToSend);
    if (notFoundFlag === 1) {
      // personalInfo is empty, data hasn't been fetched, handle accordingly
      console.log("DATA IS EMPTY");
      setLoading(true);
      try {
        console.log(formDataToSend);
        await axiosInstance.post("/api/GlanceModels", formDataToSend, {
          headers: {
            "Content-Type": "application/json", // Set the content type
          },
        });
        setLoading(false);
        toast.success("Data Inserted Successfully");
      } catch (error) {
        console.log("Error in post ", error.message);
        setLoading(false);
        toast.error("Failed to insert data");
      }
      return;
    }
    try {
      setLoading(true);
      console.log("TO send data");
      console.log(formDataToSend);
      const response = await axiosInstance.put(
        `/api/GlanceModels/${UniversityId.UniversityId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "application/json", // Set the content type
          },
        }
      ); //
      console.log("Response:", response.status);
      if (response.status === 204) {
        toast.success("Data updated successfully!");
      } else {
        toast.error("Failed to update data. Please try again.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <form className="detail-wrapper">
        <div className="input-area">
          <div className="input-fields">
            <label>Description</label>
            <textarea
              style={{ resize: "none", height: "60px", padding: "10px" }}
              placeholder="Description"
              value={formData.description}
              onChange={(e) => handleChange(e, "description")}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="input-fields">
            <label>Video URL</label>
            <textarea
              style={{ resize: "none", height: "60px", padding: "10px" }}
              placeholder="URL"
              value={formData.videoLink}
              onChange={(e) => handleChange(e, "videoLink")}
            />
          </div>
        </div>
        <div className="btn-field">
          <button className="primary-button" onClick={handleSubmit}>
            Update profile
          </button>
        </div>
        {loading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Loading.....</p>
          </div>
        )}
      </form>
    </>
  );
};

export default UniversityGlance;
