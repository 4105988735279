import React from "react";
import "./UniverisityDetailsCard.css";

const UniverisityDetailsCard = ({
  title,
  content,
  linkTo,
  selected,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={`uniCard ${selected && "selected"} `}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <h2>{title}</h2>
      <p className="uniCardContent">{content}</p>
      <a
        href={linkTo}
        className={selected ? "primary-button" : "secondary-button"}
      >
        View more
      </a>
    </div>
  );
};

export default UniverisityDetailsCard;
