import React from "react";
import { useAuth } from "../../contexts/Auth/AuthContext";
import ProfileBar from "../ProfileBar";
import Councelor from "./Councelor/CouncelorDashboard";
import Cookies from "js-cookie";

import UniversityDashboard from "../UniversityDetailsPage/UniversityDashboard/UniversityDashboard";

const ProfileWraper = () => {
  const { currentUser } = useAuth();
  const Role = currentUser.userRole;
  console.log(Role);
  const StudentUid = Cookies.get("userId");
  const CounselorUid = Cookies.get("userId");
  const UniversityUid = Cookies.get("userId");

  console.log(currentUser);
  const ProfileRender = {
    Student: <ProfileBar StudentUID={StudentUid} />,
    Counselor: <Councelor CounselorUID={CounselorUid}/>,
    University: <UniversityDashboard UniversityUID={UniversityUid}/>
  };
  return <>{ProfileRender[Role]}</>;
};

export default ProfileWraper;
