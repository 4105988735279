import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const token = Cookies.get('token');
    const userRole = Cookies.get('userRole');
    const userId = Cookies.get('userId');
    const userName = Cookies.get('userName');
    const profileImgURL = Cookies.get('profileImgURL');

    if (token && userRole && userId && userName) {
      return {
        token,
        userRole,
        userId,
        userName,
        profileImgURL,
      };
    } else {
      return null;
    }
  });

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return !!Cookies.get('token');
  });

  const login = async (values) => {
    try {
      const response = await axios.post('/api/Authentication/login', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('response', response.data);

      const { token, userRole, userId, userName, profileImgURL } =
        response.data;

      Cookies.set('token', token, { expires: 1 / 24 });
      Cookies.set('userRole', userRole, { expires: 1 / 24 });
      Cookies.set('userId', userId, { expires: 1 / 24 });
      Cookies.set('userName', userName, { expires: 1 / 24 });
      Cookies.set('profileImgURL', profileImgURL, { expires: 1 / 24 });

      setIsLoggedIn(true);
      setCurrentUser({ token, userRole, userId, userName, profileImgURL });
      return response;
    } catch (error) {
      console.error('An error occurred during login.', error);
      throw error;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    setCurrentUser(null);
    Cookies.remove('token');
    Cookies.remove('userType');
    Cookies.remove('userId');
    Cookies.remove('userName');
  };

  //   guest login function
  //   const getGuestToken = async () => {
  //     try {
  //       const response = await axios.post('/api/Authentication/generateToken', {
  //       });

  //       Cookies.set('token', response.data.token, { expires: 1 / 24 });
  //       Cookies.set('userType', 'guest', { expires: 1 / 24 });
  //       setIsLoggedIn(false);
  //       return response;
  //     } catch (error) {
  //       console.error('An error occurred during guest token creation.', error);
  //       throw error;
  //     }
  //   };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
