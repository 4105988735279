import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../components/axios";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMessage,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import UniversityRegistration from "../UniversityList/UniversityRegistration";

const UniversityEnquiry = () => {
  const [data, setData] = useState(false);
  const [dataStore, setDataStore] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inquiryData, setInquiryData] = useState([]);
  const [selectedUniversityData, setSelectedUniversityData] = useState(null);
  const toggleForm = (student) => {
    setShowForm(!showForm);
    setSelectedUniversityData(student);
    console.log(selectedUniversityData);
  };
  const toggleRegistration = () => {
    setShowForm(false);
    setRegistration(true);
  };
  const closeRegistration = () => {
    setRegistration(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`api/UniversityInquiryForms`);
        setInquiryData(response.data);
        setData(true);
        setLoading(false);
        console.log(response);
        setDataStore(response.data);
        console.log(inquiryData);
      } catch (error) {
        console.log(error);
        setData(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="info-wrapper">
      <h2>
        <span style={{ color: "var(--darkblue-color)" }}>University</span>
        {` `}Inquiry List
      </h2>
      {data && (
        <div className="table-wrapper">
          <table className="listTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Institute Name</th>
                <th>Contact Name</th>
                <th>Contact Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {inquiryData.map((student, index) => (
                <tr key={index}>
                  <td>{student.id}</td>
                  <td>{student.instituteName}</td>
                  <td>{student.contactName}</td>
                  <td>{student.contactNumber}</td>
                  <td>
                    <button
                      className="primary-button"
                      onClick={() => toggleForm(student)}
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showForm && (
        <div
          className={`${showForm ? "popup-modal" : ""}`}
          onClick={() => toggleForm()}
          style={{ position: "fixed" }}
        >
          <div className="form-wrapper popup-form">
            <h2 className="welcome-text">
              <span
                style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
              >
                Institute
              </span>{" "}
              Registration
            </h2>

            <span style={{ color: "var(--darkblue-color)", fontWeight: "700" }}>
              university Inquiry
            </span>
            <form className="form-values">
              <div className="input-wrapper">
                <label htmlFor="username">Institute Name</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faUser} className="icon" />
                  <input
                    type="text"
                    value={selectedUniversityData.instituteName}
                    autoComplete="off"
                    placeholder="Username"
                    readOnly
                  />
                </div>
              </div>
              <div
                className="flex-elements"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <div className="input-wrapper">
                  <label htmlFor="contactName">Contact Name</label>
                  <div className="input-container" style={{ height: "40px" }}>
                    <FontAwesomeIcon icon={faUser} className="icon" />
                    <input
                      value={selectedUniversityData.contactName}
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="contactNumber">Contact Number</label>
                  <div className="input-container" style={{ height: "40px" }}>
                    <FontAwesomeIcon icon={faPhone} className="icon" />
                    <input
                      value={selectedUniversityData.contactNumber}
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="email">Email</label>
                <div className="input-container" style={{ height: "40px" }}>
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <input
                    type="email"
                    value={selectedUniversityData.email}
                    autoComplete="off"
                    placeholder="Email"
                    readOnly
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="message">Message</label>
                <div
                  className="input-container"
                  style={{ height: "100px", width: "40vw" }}
                >
                  <FontAwesomeIcon icon={faMessage} className="icon" />
                  <textarea
                    style={{
                      resize: "none",
                      outline: "none",
                      border: "none",
                      width: "100%",
                      overflowY: "scroll",
                    }}
                    value={selectedUniversityData.message}
                    autoComplete="off"
                    readOnly
                  />
                </div>
              </div>
              <Button
                style={{ margin: "10px" }}
                onClick={toggleForm}
                className="secondary-button"
              >
                Close
              </Button>
              <Button
                style={{ margin: "10px 0px 10px 200px" }}
                onClick={toggleRegistration}
                className="secondary-button"
              >
                Register University
              </Button>
            </form>
          </div>
        </div>
      )}
      {registration && <UniversityRegistration onClose={closeRegistration} />}
    </section>
  );
};

export default UniversityEnquiry;
