import React from 'react';
import './AboutRightTrails.css';

const AboutRightTrails = () => {
  return (
    <section className="width-1256">
      <div className="about-right-trails-wrapper">
        <h2 className="section-title">
          The bridge between
          <br />
          <span style={{ color: 'var(--darkblue-color)' }}>
            aspiring students & world ranking insititutions
          </span>
        </h2>
        <p className="section-subtitle">
          Right Trials <b>simplifies</b> and <b>bridges</b> the gap between
          aspiring students wishing to study abroad and world ranking
          institutions.
        </p>
        <div className="sectionImages">
          {/* add images to show right trails as bridge between student and university */}
        </div>
      </div>
    </section>
  );
};

export default AboutRightTrails;
