import React from 'react';
import './BlogCard.css';
import blogImage from '../assets/images/aboutlast.png';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const BlogCard = (item) => {
  const navigate = useNavigate();
  const handleReadMore = () => {
    const formattedTitle = item.title.replace(/\s+/g, '-');
    navigate(`/blog/detail/${formattedTitle}`, {
      state: {
        blog: item,
      },
    });
  };
  return (
    <div className="blogCard">
      <div className="blogCardImage">
        {item.imageLink ? (
          <img src={item.imageLink} alt="blog" />
        ) : (
          <img src={blogImage} alt="blog" />
        )}
      </div>
      <div className="blogCardContent">
        <p>April 20, 2021</p>
        <h3>{item.title}</h3>
        <p className="blog-description">{item.description}</p>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            width: 'fit-content',
          }}
          onClick={handleReadMore}
          className="secondary-button"
        >
          Read More <IoMdArrowRoundForward />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
