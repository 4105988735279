import React, { useState } from "react";
import { IoMdArrowRoundForward } from "react-icons/io";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faUser,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

const UniversityRegistration = ({ onClose }) => {
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = "Username is required.";
      }

      if (!values.email) {
        errors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors.email = "Invalid email address.";
      }

      if (!values.password) {
        errors.password = "Password is required.";
      } else if (
        !/(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          values.password
        )
      ) {
        errors.password = "Invalid format.";
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetch(
          `/api/Authentication/register?role=university`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          setLoading(false);
          toast.success(
            "Registration successful! Check your email for confirmation."
          );
          navigate("/university-list");
        } else {
          const data = await response.json();
          setLoading(false);
          toast.error(`Registration failed: ${data.message}`);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred during registration.");
        console.error(error);
      }
    },
  });

  const toggleForm = () => {
    setShowForm(!showForm);
    navigate("/institution-inquiry");
  };

  return (
    <div className="popup-modal">
      <div className="form-wrapper popup-form">
        <h2 className="welcome-text">
          <span style={{ color: "var(--darkblue-color)", fontWeight: "700" }}>
            Institute
          </span>{" "}
          Registration
        </h2>
        <span>Register your account</span>
        <form className="form-values" onSubmit={formik.handleSubmit}>
          <div className="input-wrapper">
            <label htmlFor="username">Username</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="icon" />
              <input
                type="text"
                {...formik.getFieldProps("username")}
                autoComplete="off"
                placeholder="Username"
              />
            </div>
            {formik.touched.username && formik.errors.username && (
              <div className="error">{formik.errors.username}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="email">Email</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              <input
                type="email"
                {...formik.getFieldProps("email")}
                autoComplete="off"
                placeholder="Email"
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className="error">{formik.errors.email}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="password">Password</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="icon" />
              <input
                type={showPassword ? "text" : "password"}
                {...formik.getFieldProps("password")}
                autoComplete="off"
                placeholder="Password"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                className="icon icon-clickable"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="error">{formik.errors.password}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="icon" />
              <input
                type={showConfirmPassword ? "text" : "password"}
                {...formik.getFieldProps("confirmPassword")}
                autoComplete="off"
                placeholder="Confirm Password"
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEye : faEyeSlash}
                className="icon icon-clickable"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="error">{formik.errors.confirmPassword}</div>
              )}
          </div>
          <Button
            style={{ margin: "10px" }}
            onClick={onClose}
            className="secondary-button "
          >
            Cancel
          </Button>
          <Button style={{ margin: "10px" }} className="primary-button ">
            Register
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UniversityRegistration;
