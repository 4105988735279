import React from 'react';
import './UniversityStatus.css';
import Gra from '../../../components/assets/images/studentpost.png';
import Ach from '../../../components/assets/images/trophy.png';
import Hat from '../../../components/assets/images/studenthat.png';
import CountUp from 'react-countup';

const UniversityStatus = ({ status }) => {
  const estdDate = new Date(status.establishedDate);
  return (
    <section className="">
      <div className="university-status width-1256">
        <div className="status-card">
          <img src={Gra} alt="graduation" />
          <div className="status-text">
            <p className="stat">
              <CountUp
                start={0}
                end={status.totalStudents}
                duration={3}
                suffix={'+'}
              />
            </p>
            <p className="stat-title">Students</p>
          </div>
        </div>
        <div className="status-card">
          <img src={Ach} alt="graduation" />
          <div className="status-text">
            {status.establishedDate ? (
              <p className="stat">{estdDate.toISOString().split("T")[0]}</p>
            ) : (
              <p className="stat">---</p>
            )}
            <p className="stat-title">Established</p>
          </div>
        </div>
        <div className="status-card">
          <img src={Hat} alt="graduation" />
          <div className="status-text">
            <p className="stat">Available</p>
            <p className="stat-title">Scholarship</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniversityStatus;
