import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../components/axios";
import { toast, ToastContainer } from "react-toastify";
const Councelor = (UID) => {
  const cookieValue = Cookies.get("userId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(0);
  const [formData, setFormData] = useState({
    userId: UID.CounselorUID,
    companyName: "",
    address: "",
    yearOfEstablishment: "",
    phoneNo: "",
    contactNo: "",
    contactEmail: "",
    bankName: "",
    accountName: "",
    bankAddress: "",
    routing: "",
    swiftCode: "",
    imageName: "",
    imageFile: null,
  });
  
  console.log(formData.userId);

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setFormData({
      ...formData,
      [fieldName]: newValue,
    });
  };
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      imageFile: e.target.files[0], // Update the property name to ImageFile
    });
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const getResponse = await axiosInstance.get(
          `/api/ProfileModels/${UID.CounselorUID}`
        );
        console.log("Profile details of agent:", getResponse);
        setFormData({
          companyName: getResponse.data.companyName,
          address: getResponse.data.address,
          yearOfEstablishment: getResponse.data.yearOfEstablishment,
          phoneNo: getResponse.data.phoneNo,
          contactNo: getResponse.data.contactNo,
          contactEmail: getResponse.data.contactEmail,
          bankName: getResponse.data.bankName,
          accountName: getResponse.data.accountName,
          bankAddress: getResponse.data.bankAddress,
          routing: getResponse.data.routing,
          swiftCode: getResponse.data.swiftCode,
          imageName: getResponse.data.imageName,
        });
        setSelectedImage(getResponse.data.imageName);
        setLoading(false);
      } catch (error) {
        console.log("Get put block");
        console.log(error);
        setLoading(false);
        if (error.response.status === 404) {
          console.log("Setting flag to 1");
          setNotFound(1);
        } else {
          console.log("Gone in else");
          setNotFound(0);
        }
      }
    };
    fetchProfileDetails();
  }, [UID.CounselorUID]);
  const submit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("userId", UID.CounselorUID);
    form.append("companyName", formData.companyName);
    form.append("address", formData.address);
    form.append("yearOfEstablishment", formData.yearOfEstablishment);
    form.append("phoneNo", formData.phoneNo);
    form.append("contactNo", formData.contactNo);
    form.append("contactEmail", formData.contactEmail);
    form.append("bankName", formData.bankName);
    form.append("accountName", formData.accountName);
    form.append("bankAddress", formData.bankAddress);
    form.append("routing", formData.routing);
    form.append("swiftCode", formData.swiftCode);
    form.append("imageName", formData.imageName);
    form.append("imageFile", formData.imageFile);

    if (notFound === 1) {
      setLoading(true);
      console.log("New User inside Post request");
      try {
        await axiosInstance.post(`api/ProfileModels`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setLoading(false);
      } catch (error) {
        console.log("Error occured", error.message);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const putResponse = await axiosInstance.put(
          `api/ProfileModels/${UID.CounselorUID}`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (putResponse.status === 204) {
          setLoading(false);
          toast.success("Data updated");
        } else {
          console.log("Status");
          console.log(putResponse.status);
          setLoading(false);
          toast.error("Failed to update");
        }
      } catch (error) {
        console.log("Error in put block as:", error.message);
      }
    }
  };
  return (
    <div className="detail-wrapper">
      <ToastContainer />
      <h1 style={{ fontWeight: "500", textAlign: "center", color: "blue" }}>
        Edit Profile Details
      </h1>
      <div
        className="profile-photo"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="upload-photo"
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            border: "1px dashed #aaa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPhotoFilm}
              style={{ fontSize: "50px", color: "#aaa" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100px",
              height: "100px",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      <div className="input-area">
        <div className="input-fields">
          <label>Company Name</label>
          <input
            type="text"
            placeholder="Company Name"
            onChange={(e) => handleChange(e, "companyName")}
            value={formData.companyName}
          />
        </div>

        <div className="input-fields">
          <label>Address</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "address")}
            placeholder="Address"
            value={formData.address}
          />
        </div>
      </div>

      <div className="input-area">
        <div className="input-fields">
          <label>Year Of Establishment</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "yearOfEstablishment")}
            placeholder="Years Of Establishment"
            value={formData.yearOfEstablishment}
          />
        </div>
        <div className="input-fields">
          <label>Contact Number</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "contactNo")}
            placeholder="Contact Phone"
            value={formData.contactNo}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Phone No</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "phoneNo")}
            placeholder="Phone No"
            value={formData.phoneNo}
          />
        </div>

        <div className="input-fields">
          <label>Contact Email</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "contactEmail")}
            placeholder="Contact Email"
            value={formData.contactEmail}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Bank Name</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "bankName")}
            placeholder="Bank Name"
            value={formData.bankName}
          />
        </div>
        <div className="input-fields">
          <label>Account Name</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "accountName")}
            placeholder="Account Name"
            value={formData.accountName}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Routing</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "routing")}
            placeholder="Company Name"
            value={formData.routing}
          />
        </div>

        <div className="input-fields">
          <label>Bank Address</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "bankAddress")}
            placeholder="Bank Address"
            value={formData.bankAddress}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Swift Code</label>
          <input
            type="text"
            onChange={(e) => handleChange(e, "swiftCode")}
            placeholder="Swift Code"
            value={formData.swiftCode}
          />
        </div>
      </div>
      <div
        className="btn-field"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginTop: "20px",
          borderRadius: "1rem",
          marginBottom: "20px",
        }}
      >
        <button className="secondary-button">Discard</button>
        <button className="primary-button" onClick={submit}>
          Save Changes
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </div>
  );
};

export default Councelor;
