import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../components/axios";
import { toast } from "react-toastify";
function LanguageScore(StudentId) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: StudentId.StudentId,
    englishExamType: "",
    speakingScore: 0,
    readingScore: 0,
    writingScore: 0,
    listeningScore: 0,
    averageScore: 0,
    examDate: "",
  });
  const englishExamType = [
    "I do not have ...",
    "TOEFL",
    "IELTS",
    "Pearson",
    "Duolingo",
  ];
  const [notFoundFlag, setNotFoundFlag] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/LanguageScoreModels/${StudentId.StudentId}`
        );
        setFormData(response.data);
        const examsDate = new Date(response.data.examDate);
        setFormData({
          englishExamType: response.data.englishExamType,
          speakingScore: response.data.speakingScore,
          readingScore: response.data.readingScore,
          writingScore: response.data.writingScore,
          examDate: examsDate.toISOString().split("T")[0],
          listeningScore: response.data.listeningScore,
          averageScore: response.data.averageScore,
        });
        setLoading(false);
        console.log("Inside Get block");
        setNotFoundFlag(0);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 404) {
          console.log("Data not found:", error.response.data);
          setNotFoundFlag(1);
        } else {
          console.error("Error fetching data:", error.message);
          setNotFoundFlag(0);
        }
      }
    };

    if (StudentId.StudentId) {
      fetchData();
    }
  }, [StudentId.StudentId]);

  const handleChange = (input, fieldName) => {
    let newValue;
    if (
      fieldName === "speakingScore" ||
      fieldName === "readingScore" ||
      fieldName === "writingScore" ||
      fieldName === "listeningScore" ||
      fieldName === "averageScore"
    ) {
      let maxScore;
      switch (formData.englishExamType) {
        case "TOEFL":
          maxScore = 30;
          break;
        case "IELTS":
          maxScore = 9;
          break;
        case "Pearson":
          maxScore = 90;
          break;
        case "Duolingo":
          maxScore = 160;
          break;
        default:
          maxScore = 0;
          break;
      }
      newValue = parseInt(input.target.value);
      if (newValue > maxScore) {
        newValue = maxScore;
      }
    } else {
      newValue = input.target ? input.target.value : input.label;
    }

    setFormData({
      ...formData,
      [fieldName]: newValue,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("userID", StudentId.StudentId);
    form.append("englishExamType", formData.englishExamType);
    form.append("speakingScore", formData.speakingScore);
    form.append("readingScore", formData.readingScore);
    form.append("writingScore", formData.writingScore);
    form.append("listeningScore", formData.listeningScore);
    form.append("averageScore", formData.listeningScore);
    form.append("examDate", formData.examDate);

    if (notFoundFlag === 1) {
      setLoading(true);
      console.log("Inside post block ");
      console.log(form);
      // If data doesn't exist, fallback to creating a new entry with a POST request
      try {
        const response = await axiosInstance.post(
          `/api/LanguageScoreModels`,
          form,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("inside post block");
        console.log(response);
        toast.success("Data updated successfully");
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to update");
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        // Try to update existing data with a PUT request
        const response = await axiosInstance.put(
          `/api/LanguageScoreModels/${StudentId.StudentId}`,
          form,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Inside put block");
        console.log(response);
        toast.success("Data updated successfully");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to update");
        console.log(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
        {" "}
        Setup Language Score
      </h3>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="name" className="input-field ">
            Latest English Exam Type
          </label>
          <select
            type="text"
            className="form-input width-actual-100"
            placeholder="I do not have..."
            value={formData.englishExamType}
            onChange={(e) => handleChange(e, "englishExamType", e.target.value)}
          >
            {englishExamType.map((exam, index) => (
              <option key={index} value={exam} disabled="">
                {exam}
              </option>
            ))}
          </select>
        </div>
        <div className="input-fields">
          <label htmlFor="name" className="input-field">
            Speaking Score
          </label>
          <input
            type="number"
            className="form-input"
            value={formData.speakingScore}
            onChange={(e) => handleChange(e, "speakingScore")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="name" className="input-field">
            Reading Score
          </label>
          <input
            type="number"
            className="form-input"
            value={formData.readingScore}
            onChange={(e) => handleChange(e, "readingScore")}
          />
        </div>
        <div className="input-fields">
          <label htmlFor="name" className="input-field">
            Writing Score
          </label>
          <input
            type="number"
            className="form-input"
            value={formData.writingScore}
            onChange={(e) => handleChange(e, "writingScore")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="name" className="input-field">
            Listening Score
          </label>
          <input
            type="number"
            className="form-input"
            value={formData.listeningScore}
            onChange={(e) => handleChange(e, "listeningScore")}
          />
        </div>
        <div className="input-fields">
          <label htmlFor="name" className="input-field">
            Average Score
          </label>
          <input
            type="number"
            className="form-input"
            value={formData.averageScore}
            onChange={(e) => handleChange(e, "averageScore")}
          />
        </div>
      </div>
      <div className="input-fields">
        <label htmlFor="name" className="input-field">
          Exam Date
        </label>
        <input
          type="date"
          className="form-input"
          value={formData.examDate}
          onChange={(e) => handleChange(e, "examDate")}
        />
      </div>
      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
}

export default LanguageScore;
