import React, { useState } from 'react';
import './Navbar.css';
import Logo from '.././assets/images/alternative_logo.png';
import user from '.././assets/images/user-profile.png';
import SidebarBtn from '.././assets/images/sidebar_btn.svg';
import CloseCrossBtn from '.././assets/images/close_cross.svg';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth/AuthContext';
import { CiSearch } from 'react-icons/ci';

const Navbar = ({ isTransparent }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { logout } = useAuth();
  const { currentUser } = useAuth();

  const handleLogout = () => {
    logout();
  };
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  return (
    <header className={isTransparent ? '' : 'dark-blue'}>
      <nav className="navWrapper width-1256">
        <a href="/" className="company-logo">
          <img src={Logo} alt="company logo" />
        </a>
        <div className="dropdown">
          <div className="search-box drop-btn">
            <CiSearch size={24} className="search-icon" />
            <input
              type="text"
              placeholder="I need assistance for..."
              className="search-input"
              disabled
              style={{color:'white'}}
            />
          </div>
          <div class="dropdown-content-search">
            <a href="university/search">Finding my dream university</a>
            <a href="/">Why College matters</a>
            <a href="university/search">Your Application journey</a>
            <a href="/">Paying for college</a>
          </div>
        </div>
        <ul className="navLinks">
          <li>
            <a href="/student" className={isActive('/student') ? 'active' : ''}>
              Student
            </a>
          </li>
          <li>
            <a
              href="/recruitment-partner"
              className={isActive('/recruitment-partner') ? 'active' : ''}
            >
              Recruiter
            </a>
          </li>
          <li>
            <a
              href="/institution"
              className={isActive('/institution') ? 'active' : ''}
            >
              Institution
            </a>
          </li>
        </ul>
        {currentUser ? (
          <div className="userDetails">
            <p>Hello {currentUser.userName}</p>
            <div className="dropdown dropbtn image-container">
              {currentUser?.profileImgURL !== '' ? (
                <img
                  src={currentUser.profileImgURL}
                  alt="user"
                  className="user"
                />
              ) : (
                <img src={user} alt="user" className="user" />
              )}
              <div className="dropdown-content">
                <a href="/profile">Profile</a>
                <a href="/settings">Settings</a>
                <a
                  href={`${
                    currentUser.userRole === 'student'
                      ? 'member/login'
                      : '/agent/login'
                  }`}
                  onClick={handleLogout}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div class="navButtons">
            <div class="dropdown">
              <button class="transparent-button dropbtn">Login</button>
              <div class="dropdown-content">
                <a href="member/login">Student</a>
                <a href="agent/login">Counselor</a>
              </div>
            </div>
            <div class="dropdown">
              <button class="primary-button dropbtn">Register</button>
              <div class="dropdown-content">
                <a href="member/register">Student</a>
                <a href="agent/register">Counselor</a>
              </div>
            </div>
          </div>
        )}

        {/* sidebar */}
        <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
          <img
            src={showSidebar ? CloseCrossBtn : SidebarBtn}
            alt="sidebar button"
          />
        </div>
        <div
          className={`sidebar dark-blue
           ${showSidebar ? ' active' : ''}`}
        >
          <ul>
            <li>
              <a
                href="/student"
                className={isActive('/student') ? 'active' : ''}
              >
                Student
              </a>
            </li>
            <li>
              <a
                href="/recruitment-partner"
                className={isActive('/recruitment-partner') ? 'active' : ''}
              >
                Recruitment Partner
              </a>
            </li>
            <li>
              <a
                href="/institution"
                className={isActive('/institution') ? 'active' : ''}
              >
                Institution
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {showSidebar && <div className="overlay" onClick={toggleSidebar}></div>}
    </header>
  );
};

export default Navbar;
