import React from 'react';
import './FinanceCard.css';

const FinanceCard = ({ items, isReq }) => {
  return (
    <div className="cards-wrapper">
      {items.length === 0 && <p>Currently Not available</p>}
      {items.map((item, index) => (
        <div key={index} className="finance-card-wrapper">
          <h2>{item.name}</h2>
          <table className="listTable">
            {isReq ? (
              <tbody>
                <tr>
                  <td>Minimum Eductaion</td>
                  <td>{item.education}</td>
                </tr>
                <tr>
                  <td>Minimum GPA</td>
                  <td>{item.gpa}</td>
                </tr>
                <tr>
                  <td>Minimum IELTS</td>
                  <td>{item.ielts}</td>
                </tr>
                <tr>
                  <td>Minimum TOEFL</td>
                  <td>{item.toefl}</td>
                </tr>
                <tr>
                  <td>Minimum Pearson</td>
                  <td>{item.pearson}</td>
                </tr>
                <tr>
                  <td> Duolingo</td>
                  <td>{item.duolingo}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>Tution</td>
                  <td>{item.tution}</td>
                </tr>
                <tr>
                  <td>Accomodation</td>
                  <td>{item.accomodation}</td>
                </tr>
                <tr>
                  <td>Application</td>
                  <td>{item.application}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{item.total}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      ))}
    </div>
  );
};

export default FinanceCard;
