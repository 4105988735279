import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FooterItem.css";
import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <div className="contact">
      <h1 className="footer-heading"
        style={{
          marginTop: "50px",
        }}
      >
        Contact Us
      </h1>
      <p className="intro-text">
        We're here to help. Don't hesitate to get in touch with our expert
        support team who can help answer all of your questions.
      </p>
      <div className="contact-icons">
        <div className="contact-contents">
          <FontAwesomeIcon
            icon={faMailBulk}
           className="socialmedia-icons"
          />
          <p>Email</p>
          <p>inquiry@righttrials.com</p>
          <p>Please Email us with your enquiries</p>
        </div>
        <div className="contact-contents">
          <FontAwesomeIcon
            icon={faPhone}
           className="socialmedia-icons"
          />
          <p>Phone</p>
          <p>+123456789</p>
          <p>Monday–Friday, 9 AM–5:30 PM EST</p>
        </div>
        <div className="contact-contents">
          <FontAwesomeIcon
            icon={faPhone}
           className="socialmedia-icons"
          />
          <p>Phone</p>
          <p>+123456789</p>
          <p>Monday–Friday, 9 AM–5:30 PM EST</p>
        </div>
      </div>
      <p className='Social-text'>
        If you are an existing customer and have a question, please contact your
        Account Representative for assistance. For questions about a student
        application, please leave a message in the Notes section of the
        application and our Customer Experience team will be in touch.
      </p>
      <div className="social-media">
      <FontAwesomeIcon
            icon={faFacebook}
           className="socialmedia-icons"
          />
           <FontAwesomeIcon
            icon={faInstagram}
           className="socialmedia-icons"
          />
           <FontAwesomeIcon
            icon={faTwitter}
           className="socialmedia-icons"
          />
           <FontAwesomeIcon
            icon={faYoutube}
           className="socialmedia-icons"
          />
           <FontAwesomeIcon
            icon={faLinkedinIn}
           className="socialmedia-icons"
          />

      </div>
    </div>
  );
};

export default Contact;
