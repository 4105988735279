import React, { useEffect, useState } from 'react';
import './Universities.css';
import { CiSearch } from 'react-icons/ci';
import { UniversityCard, UniversityFilter } from '../../components';
import { axiosInstance } from '../../api';

const Universities = () => {
  const [filterOn, setFilterOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    name: '',
    country: '',
    educationLevel: '',
    state: '',
    gpaScore: '',
    instituteType: '',
    programType: '',
    fallProgram: false,
    summerProgram: false,
    springProgram: false,
    majorProgram: '',
    areaOfStudy: '',
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
    pageSize: 8,
  });
  const [unidata, setunidata] = useState({
    data: [],
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/CoreModels/Pagination?page=${pagination.currentPage}${
            pagination.pageSize ? `&pageSize=${pagination.pageSize}` : ''
          }`
        );
        setunidata(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination.currentPage, pagination.pageSize]);

  const handleFilter = () => {
    setFilterOn(!filterOn);
  };
  const handleResetFilter = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/CoreModels/Pagination?page=${pagination.currentPage}${
          pagination.pageSize ? `&pageSize=${pagination.pageSize}` : ''
        }`
      );
      setunidata(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }

    setFilterData({
      name: '',
      country: '',
      educationLevel: '',
      state: '',
      gpaScore: '',
      instituteType: '',
      programType: '',
      fallProgram: false,
      summerProgram: false,
      springProgram: false,
      majorProgram: '',
      areaOfStudy: '',
    });

    setFilterOn(false);
  };

  const handleSearch = async (e) => {
    try {
      let searchValue = e.target.value;
      setLoading(true);
      if (searchValue.trim() === '') {
        const response = await axiosInstance.get(
          `/api/CoreModels/Pagination?page=${pagination.currentPage}${
            pagination.pageSize ? `&pageSize=${pagination.pageSize}` : ''
          }`
        );
        setunidata(response.data);
        setLoading(false);
      } else {
        const response = await axiosInstance.get(
          `/api/Search?name=${searchValue}`
        );
        try {
          const response2 = await axiosInstance.get(
            `/api/CoreModels/${response.data[0]}`
          );
          setunidata({ data: [response2.data] });
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleApplyFilter = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        name: filterData.name,
        Country: filterData.country,
        EducationLevel: filterData.educationLevel,
        State: filterData.state,
        GPAScore: filterData.gpaScore,
        InstitutionType: filterData.instituteType,
        ProgramLevel: filterData.programType,
        Fall: filterData.fallProgram,
        Summer: filterData.summerProgram,
        Spring: filterData.springProgram,
        AreaOfStudy: filterData.areaOfStudy,
        Major: filterData.majorProgram,
      });

      const response = await axiosInstance.get(
        `/api/Search?${queryParams.toString()}`
      );
      if (response.status === 200) {
        const data = response.data;

        try {
          const response2 = await axiosInstance.get(
            `/api/CoreModels/${response.data[0]}`
          );
          setunidata({ data: [response2.data] });
          setLoading(false);
        } catch (error2) {
          console.log('error2');
          setLoading(false);
        }
      } else {
        console.error(`API request failed with status ${response.status}`);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error filtering events:', error.message);
      setLoading(false);
    }
    setFilterOn(false);
  };
  function handlePage(type) {
    setLoading(true);
    setpagination((prevPagination) => {
      const newPage =
        type === 'next'
          ? prevPagination.currentPage + 1
          : prevPagination.currentPage - 1;

      //   fetchData({ currentPage: newPage });
      setLoading(false);
      return {
        ...prevPagination,
        currentPage: newPage,
      };
    });
  }

  return (
    <section className="universitiesContainer">
      <div className="width-1256">
        <h2
          style={{ textAlign: 'center', padding: '50px 0', fontSize: '1.8rem' }}
        >
          Unleash Your Academic Adventure with{' '}
          <span style={{ color: 'var(--darkblue-color)' }}>Right Trails</span>
        </h2>

        <div className="search-box">
          <CiSearch size={24} className="search-icon" />
          <input
            type="text"
            placeholder="Explore the university of your dreams! Search here..."
            className="search-input"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e);
              }
            }}
          />
        </div>

        <UniversityFilter
          filterOn={filterOn}
          handleFilter={handleFilter}
          handleResetFilter={handleResetFilter}
          handleApplyFilter={handleApplyFilter}
          filterData={filterData}
          setFilterData={setFilterData}
        />
        {loading ? (
          <div className="content-container">
            <p>Loading.....</p>
          </div>
        ) : (
          <div className="content-container">
            <div className="universityCards">
              {unidata.data.map((uni, index) => (
                <div key={index}>
                  <UniversityCard item={uni} />
                </div>
              ))}
            </div>
            {/* {unidata.data.length === 0 ? (
          ) : (
            <div className="universityCards">
              <h2>No universities found</h2>
            </div>
          )} */}
            <div className="pagination">
              <button
                onClick={() => handlePage('previous')}
                disabled={unidata.currentPage === 1}
              >
                Previous
              </button>
              <span>{`Page ${unidata.currentPage} of ${unidata.totalPages}`}</span>

              <button
                onClick={() => handlePage('next')}
                disabled={
                  unidata.currentPage === unidata.totalPages ||
                  !unidata.totalPages
                }
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Universities;
