import React, { useEffect, useState } from "react";
import CountryList from "react-select-country-list";
import Select from "react-select";
import { AiOutlinePlus } from "react-icons/ai";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";

function Academic_Information(StudentId) {
  const [loading, setLoading] = useState(false);

  const countries = CountryList().getData();

  const levels = [
    "English Program",
    "High School",
    "Diploma",
    "Advanced Diploma",
    "Graduate Diploma",
    "Pathway Degree",
    "Associate Degree",
    "Bachelor's Degree",
    "Master's Degree",
  ];

  const [formData, setFormData] = useState([]);
  const [submitted, setSubmitted] = useState(true);
  const addNewForm = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        historyId: 0,
        userId: StudentId.StudentId,
        schoolName: "",
        streetName: "",
        country: "",
        province: "",
        zipCode: "",
        cityName: "",
        passedYear: 0,
        levelOfStudy: "",
      },
    ]);
  };

  const deleteForm = async (index) => {
    setLoading(true);
    const deletedForm = formData[index];

    if (deletedForm && deletedForm.historyId) {
      try {
        setLoading(true);
        const deteleResponse = await axiosInstance.delete(
          `/api/AcademicInformationModels/${deletedForm.historyId}`
        );
        console.log(deteleResponse);
        const updatedForms = [...formData];
        updatedForms.splice(index, 1);
        setFormData(updatedForms);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete");
        setLoading(false);
      }
    } else {
      const updatedForms = [...formData];
      updatedForms.splice(index, 1);
      setFormData(updatedForms);
    }
    setSubmitted(true);
  };

  const handleChange = (index, fieldName, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [fieldName]: value,
    };
    setFormData(updatedFormData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const getResponse = await axiosInstance.get(
          `/api/AcademicInformationModels/GetAcademicInformtationModelForUser/${StudentId.StudentId}`
        );
        setFormData(getResponse.data);
        console.log(getResponse.data);
        console.log("Information fetched successfully");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Inside error block of get");

      }
      setSubmitted(false);
    };

    if (submitted) {
      fetchData();
    }
  }, [submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Inside handle Submit");

    try {
      setLoading(true);
      const promises = formData.map(async (form) => {
        const formDataToSend = new FormData();
        for (const key in form) {
          formDataToSend.append(key, form[key]);
        }

        const putResponse = await axiosInstance.put(
          `/api/AcademicInformationModels/${form.historyId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log(putResponse);
      });

      await Promise.all(promises);
      toast.success("Data updated successfully");
      setLoading(false);
    } catch (error) {
      console.log("Error in Put block", error.message);
      console.log(error.response);
      toast.error("Failed to update");
      setLoading(false);
    }
    setSubmitted(true);
  };

  return (
    <>
      <h3 style={{ textAlign: "center", color: "blue", fontWeight: "500" }}>
        Setup your Academic Information
      </h3>
      {formData.map((form, index) => (
        <form key={index} onSubmit={handleSubmit}>
          {index !== 0 && (
            <h3
              style={{ color: "blue", textAlign: "center", fontWeight: "500" }}
            >
              Academic Details {index + 1}
            </h3>
          )}
          <hr style={{ marginTop: "20px" }} />

          <div className="input-area">
            <div className="input-fields">
              <label htmlFor="" className="form-label">
                School Name
              </label>
              <input
                type="text"
                placeholder="School name"
                value={form.schoolName}
                onChange={(e) =>
                  handleChange(index, "schoolName", e.target.value)
                }
              />
            </div>
            <div className="input-fields">
              <label htmlFor="" className="form-label">
                Street Name
              </label>
              <input
                type="text"
                placeholder="Street name"
                value={form.streetName}
                onChange={(e) =>
                  handleChange(index, "streetName", e.target.value)
                }
              />
            </div>
          </div>

          <div className="input-area">
            <div className="input-fields">
              <label htmlFor="">Country</label>
              <Select
                id="country"
                name="country"
                options={countries}
                placeholder="Select a country"
                value={countries.find((c) => c.value === form.country)}
                onChange={(selectedOption) =>
                  handleChange(index, "country", selectedOption.value)
                }
                classNamePrefix="custom-select"
              />
            </div>
            <div className="input-fields">
              <label htmlFor="" className="form-label">
                Level of study
              </label>
              <select
                id="country"
                name="country"
                className="form-select"
                placeholder="Select level of study"
                value={form.levelOfStudy}
                onChange={(e) =>
                  handleChange(index, "levelOfStudy", e.target.value)
                }
              >
                {levels.map((level, index) => (
                  <option key={index} value={level} disabled="">
                    {level}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="input-area">
            <div className="input-fields">
              <label htmlFor="">Province</label>
              <input
                type="text"
                placeholder="Province"
                value={form.province}
                onChange={(e) =>
                  handleChange(index, "province", e.target.value)
                }
              />
            </div>
            <div className="input-fields">
              <label htmlFor="">Zip Code</label>
              <input
                type="text"
                placeholder="Zip Code"
                value={form.zipCode}
                onChange={(e) => handleChange(index, "zipCode", e.target.value)}
              />
            </div>
          </div>
          <div className="input-area">
            <div className="input-fields">
              <label htmlFor="">City Name</label>
              <input
                type="text"
                placeholder="City"
                value={form.cityName}
                onChange={(e) =>
                  handleChange(index, "cityName", e.target.value)
                }
              />
            </div>
            <div className="input-fields">
              <label htmlFor="">Passed Year</label>
              <input
                type="number"
                placeholder="Passed Year"
                value={form.passedYear}
                onChange={(e) =>
                  handleChange(index, "passedYear", e.target.value)
                }
              />
            </div>
          </div>

          <div
            className="btn-field"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            {index === formData.length - 1 && (
              <button className="primary-button" onClick={addNewForm}>
                <AiOutlinePlus size={15} className="sign" />
                Add new school info
              </button>
            )}
            {index !== 0 && (
              <button
                className="secondary-button"
                onClick={() => deleteForm(index)}
              >
                Delete
              </button>
            )}
          </div>
        </form>
      ))}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
    </>
  );
}

export default Academic_Information;
