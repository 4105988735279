import React, { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";

function GPAScore(StudentId) {
  const [formData, setFormData] = useState({
    userId: StudentId.StudentId,
    gpaScale: "",
    gpaScore: "",
  });

  const [errors, setErrors] = useState({
    gpaScale: "",
    gpaScore: "",
  });

  const [loading, setLoading] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(0);

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    let newFormData = { ...formData, [fieldName]: value };
    let newErrors = { ...errors };

    // Basic validation for GPA Scale and GPA Score
    if (fieldName === "gpaScale") {
      newFormData.gpaScore = ""; // Clear GPA Score when scale changes
      newErrors.gpaScale = value ? "" : "GPA Scale is required.";
    } else if (fieldName === "gpaScore") {
      if (newFormData.gpaScale === "1-100") {
        if (isNaN(value) || value < 1 || value > 100) {
          newErrors.gpaScore = "GPA Score must be a number between 1 and 100.";
        } else {
          newErrors.gpaScore = "";
        }
      } else if (newFormData.gpaScale === "F-A+") {
        const validGrades = ["F", "D", "C", "B", "A", "A+"];
        if (!validGrades.includes(value)) {
          newErrors.gpaScore = "GPA Score must be one of F, D, C, B, A, A+.";
        } else {
          newErrors.gpaScore = "";
        }
      } else if (newFormData.gpaScale === "0-4") {
        if (isNaN(value) || value < 0 || value > 4) {
          newErrors.gpaScore = "GPA Score must be a number between 0 and 4.";
        } else {
          newErrors.gpaScore = "";
        }
      }
    }

    setFormData(newFormData);
    setErrors(newErrors);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/GPAModels/${StudentId.StudentId}`
        );
        setFormData(response.data);
        setFormData({
          userId: StudentId.StudentId,
          gpaScale: response.data.gpaScale,
          gpaScore: response.data.gpaScore,
        });
        setLoading(false);
        setNotFoundFlag(0);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNotFoundFlag(1);
        } else {
          setNotFoundFlag(0);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [StudentId.StudentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("userId", StudentId.StudentId);
    form.append("gpaScale", formData.gpaScale);
    form.append("gpaScore", formData.gpaScore);

    setLoading(true);

    try {
      if (notFoundFlag === 1) {
        const postResponse = await axiosInstance.post(`/api/GPAModels`, form, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Data created successfully");
        console.log(postResponse);
      } else {
        const putResponse = await axiosInstance.put(
          `/api/GPAModels/${StudentId.StudentId}`,
          form,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Data updated successfully");
        console.log(putResponse);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to update");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
        Setup GPA Score
      </h3>

      <div className="input-fields" style={{ marginBottom: "20px" }}>
        <label htmlFor="gpaScale">GPA Scale</label>
        <select
          id="gpaScale"
          value={formData.gpaScale}
          onChange={(e) => handleChange(e, "gpaScale")}
        >
          <option value="">Select GPA Scale</option>
          <option value="1-100">1-100</option>
          <option value="F-A+">F-A+</option>
          <option value="0-4">0-4</option>
        </select>
        {errors.gpaScale && <p style={{ color: "red" }}>{errors.gpaScale}</p>}
      </div>

      <div className="input-fields">
        <label htmlFor="gpaScore">GPA Score</label>
        <input
          type="text"
          id="gpaScore"
          placeholder="Please enter your GPA Score"
          value={formData.gpaScore}
          onChange={(e) => handleChange(e, "gpaScore")}
        />
        {errors.gpaScore && <p style={{ color: "red" }}>{errors.gpaScore}</p>}
      </div>

      <div className="btn-field">
        <button
          className="primary-button"
          disabled={!!errors.gpaScale || !!errors.gpaScore}
          type="submit"
        >
          Update profile
        </button>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
}

export default GPAScore;
