import React from 'react';
import './VideoSection.css';

const VideoSection = () => {
  return (
    <section id="video" className="videoSectionContainer">
      <div className="videoContent width-1256">
        <h2 className="videoSectionTitle">
          We Help Students Get Admitted Into the Best <br /> Institutions
        </h2>
        <p className="videoSectionSubtitle">
          Right Trail Expertise in Securing Admissions to Top International
          Institutions.
          <br /> Empowering Your Ambitions, Crafting Your Global Educational
          Pathway.
        </p>
        <div className="videoSectionImage">
          <img
            src="https://righttrails.com/wp-content/uploads/2024/01/Landing-Page-Product-Features–Student_V005.gif"
            alt="Right Trail Expertise in Securing Admissions to Top International Institutions."
          />
        </div>
        <a href="/student" className="secondary-button">
          View More
        </a>
      </div>
    </section>
  );
};

export default VideoSection;
