import React, { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UniversityGallery = (UniversityId) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: UniversityId.UniversityId,
    historyId: 0,
    imageLink: [],
    imageFile: [],
  });

  const [images, setImages] = useState([]);

  const handleGalleryFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    setFormData((prevFormData) => ({
      ...prevFormData,
      imageFile: [...prevFormData.imageFile, ...selectedFiles],
    }));

    // Clear the file input
    e.target.value = "";
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("Inside get block");
      console.log(formData.imageFile);

      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/GalleryModels/GalleryList/${UniversityId.UniversityId}`
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageLink: response.data[0]?.itemLink || [],
          imageFile: [],
        }));
        if (response.data) {
          const imageLinks = response.data.map((item) => ({
            historyId: item.historyId,
            itemLink: item.itemLink,
          }));
          setImages(imageLinks);
        }
        setLoading(false);

        console.log(formData);
        console.log(images);

        console.log("image ", response.data[0].itemLink);
      } catch (response) {
        setLoading(false);
        console.log(response);
      }
    };
    fetchData();
  }, [UniversityId.UniversityId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData();
    form.append("historyId", formData.historyId);
    form.append("userId", formData.userId);
    formData.imageFile.forEach((file) => {
      form.append("imageFile", file);
    });

    try {
      console.log(form);
      const postResponse = await axiosInstance.post(
        `/api/GalleryModels`,

        form
      );

      toast.success("Uploaded Successfully");
      setImages((prevImages) => [...prevImages, postResponse.data]);
      console.log(postResponse);
      setFormData({
        userId: UniversityId.UniversityId,
        historyId: 0,
        imageLink: [],
        imageFile: [],
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error uploading files");
    }
    setLoading(false);
  };
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      console.log(id);
      await axiosInstance.delete(`/api/GalleryModels/${id}`);
      setImages((prevImages) =>
        prevImages.filter((image) => image.historyId !== id)
      );

      toast.success("Deleted successfully");
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error occured");
      setLoading(false);
    }
  };

  return (
    <>
      <form className="detail-wrapper" onSubmit={handleSubmit}>
        <div className="input-area">
          <div className="file-wrapper">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleGalleryFileChange}
            />
          </div>
        </div>

        <div className="image-container">
          {images.length === 0 ? (
            <p>No images found.</p>
          ) : (
            images.map(({ historyId, itemLink }) => (
              <div key={historyId} className="image-wrapper">
                <img src={itemLink} alt={`Gallery item ${historyId}`} />
                <FontAwesomeIcon
                  icon={faX}
                  className="delete-icon"
                  onClick={() => handleDelete(historyId)}
                />
              </div>
            ))
          )}
        </div>
        <div className="btn-field">
          <button type="submit" className="primary-button">
            Update profile
          </button>
        </div>
        {loading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Loading.....</p>
          </div>
        )}
      </form>
    </>
  );
};

export default UniversityGallery;
