import React from "react";

const Disclaimer = () => {
  return (
    <div>
      <h1 className="footer-heading"> Disclaimer</h1>
    </div>
  );
};

export default Disclaimer;
