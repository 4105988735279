import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const Carousel = ({ items }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
  };
  return (
    <div className="carousel-container">
      <Slider {...sliderSettings}>
        {items.map((item) => (
          <div key={item.id} className="carousel-slide">
            <img src={item.image} className="carousel-image" alt="Carousel" />
            <h3 className="carousel-title">{item.title}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
