import React from 'react';
import { JoinRightTrails } from '../../sections';

const RecruitmentPartnerPage = () => {
  return (
    <>
      <JoinRightTrails />
    </>
  );
};

export default RecruitmentPartnerPage;
