import React from 'react';
import './AuthLayout.css';
import coverImageLogin from '../components/assets/images/loginback.png';
import coverImageRegister from '../components/assets/images/registerback.png';
import counselorImageLogin from '../components/assets/images/Registration.jpg'
import counselorImageRegistration from '../components/assets/images/CounselorRegister.jpg'
import Logo from '../components/assets/images/alternative_logo.png';
import { Outlet, useLocation } from 'react-router-dom';
import aero from '../components/assets/images/aeroplane.png';
import Taj from '../components/assets/images/Taj.png';
import Tower from '../components/assets/images/tower.png';
import church from '../components/assets/images/building.png';
import gate from '../components/assets/images/half.png';

const AuthLayout = () => {
  const location = useLocation();
  const isLogin = location.pathname.includes('login');
  const isStudent = location.pathname.includes('member');
  const getImage = () => {
    if (isLogin) {
        if (isStudent) {
            return coverImageLogin;
        } else {
            return counselorImageLogin;
        }
    } else {
        if (isStudent) {
            return coverImageRegister;
        } else {
            return counselorImageRegistration;
        }
    }
};
  return (
    <section
      className={`auth-layout-container ${isLogin ? '' : 'alternate-flow'}`}
    >
      <div className="navigation-buttons">
        <a
          className={`${isLogin ? 'transparent-button' : 'secondary-button'}`}
          href="/"
        >
          Home
        </a>
        {isLogin ? (
          <a
            className="secondary-button"
            href={isStudent ? '/agent/login' : '/member/login'}
          >
            Login as {isStudent ? 'Counselor' : 'Student'}
          </a>
        ) : (
          <a
            className="transparent-button"
            href={isStudent ? '/agent/register' : '/member/register'}
          >
            Register as {isStudent ? 'Counselor' : 'Student'}
          </a>
        )}
      </div>
      <div className="left-section ">
        <div className="coverImage">
          <img
            src={getImage()}
            alt="background_image"
            className="images"
          />
        </div>
        <div className="left-section-content">
          <a href="/" className="company-logo">
            <img src={Logo} alt="company logo" />
          </a>
          <p>
          {isStudent ? ` Embark on the journey to your dream university, the` : 'Empower Your Students Towards Higher Education Success! with the'}
            {` `}
            <span style={{ color: 'var(--darkblue-color)' }}>Right Trials</span>
            {` `}
            way.
          </p>
        </div>
      </div>
      <div className="right-section">
        <div className="side-image">
          <img src={aero} alt="aeroplane" />
        </div>
        <div className="right-section-content">
          <Outlet />
        </div>
        <div className="footer-images">
          <img src={Taj} alt="Taj Mahal" />
          <div className="images-collection">
            <img src={Tower} alt="Tower" />
            <img src={church} alt="Church" />
            <img src={gate} alt="Gate" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
