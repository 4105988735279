import React from 'react';
import './BlogPage.css';
import { FaXTwitter } from 'react-icons/fa6';
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa';
import blogImage from '../../components/assets/images/aboutlast.png';
import { LuMessagesSquare } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';

const BlogDetail = () => {
  const location = useLocation();
  const { blog } = location.state;
  console.log(blog);
  return (
    <>
      <div style={{ backgroundColor: '#bebebe' }}>
        <div className="blogSectionTitle">
          <p>Learning | Sep 25, 2022</p>
          <h2>{blog.title}</h2>
        </div>
      </div>
      <section className="width-1256 blog-wrapper">
        <div className="blogContainer">
          <div className="blogCard">
            <div className="blogCardImage">
              {blog.imageLink ? (
                <img src={blog.imageLink} alt="blog" />
              ) : (
                <img src={blogImage} alt="blog" />
              )}
            </div>
            <div className="blogCardContent">
              <p className="blog-description">{blog.description}</p>
            </div>
          </div>
        </div>
        <div className="blog-right-section">
          <div className="feature-post-container">
            <h2>Author</h2>
            <div className="feature-post">
              <div className="feature-post-card">
                <img src={blogImage} alt="feature-post" />
                <div className="post-details">
                  <h3>Right Trails</h3>
                  <p>Author</p>
                  <div className="social-media">
                    <div className="social-links">
                      <a href="/" className="social-link">
                        <FaFacebook size={20} />
                      </a>
                      <a href="/" className="social-link">
                        <FaInstagramSquare size={20} />
                      </a>
                      <a href="/" className="social-link">
                        <FaXTwitter size={20} />
                      </a>
                      <a href="/" className="social-link">
                        <FaYoutube size={20} />
                      </a>
                      <a href="/" className="social-link">
                        <FaLinkedin size={20} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-post-container">
            <h2>
              <span style={{ color: 'var(--darkblue-color)' }}>Feature</span>
              {` `}posts
            </h2>
            <div className="feature-post">
              <div className="feature-post-card">
                <img src={blogImage} alt="feature-post" />
                <div className="post-details">
                  <p>April 20, 2021</p>
                  <h3>Post Title</h3>
                </div>
              </div>
              <div className="feature-post-card">
                <img src={blogImage} alt="feature-post" />
                <div className="post-details">
                  <p>April 20, 2021</p>
                  <h3>Post Title</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="social-media">
            <h2>
              Social{' '}
              <span style={{ color: 'var(--darkblue-color)' }}>Media</span>
            </h2>
            <div className="social-links">
              <a href="/" className="social-link">
                <FaFacebook size={32} />
              </a>
              <a href="/" className="social-link">
                <FaInstagramSquare size={32} />
              </a>
              <a href="/" className="social-link">
                <FaXTwitter size={32} />
              </a>
              <a href="/" className="social-link">
                <FaYoutube size={32} />
              </a>
              <a href="/" className="social-link">
                <FaLinkedin size={32} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="message-popup-container">
        <div className="message-popup">
          <LuMessagesSquare size={28} />
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
