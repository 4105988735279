import React, { useState } from "react";
import UniversityCore from "./UniversityCore";
import UniversityCourses from "./UniversityCourses";
import UniversityFinance from "./UniversityFinance";
import UniversityGlance from "./UniversityGlance";
import UniversityRequirements from "./UniversityRequirements";
import UniversityGallery from "./UniversityGallery";

const UniversityDashboard = (UID) => {
  const [active, setActive] = useState("Core");

  const tabStyle = {
    display: "flex",
    justifyContent: "space-between",
    listStyleType: "none",
    padding: 0,
  };

  const itemStyle = {
    margin: "0 10px",
    cursor: "pointer",
  };
  const renderContent = () => {
    switch (active) {
      case "Core":
        return <UniversityCore UniversityId={UID.UniversityUID}/>;
      case "Courses":
        return <UniversityCourses UniversityId={UID.UniversityUID}/>;
      case "Finance":
        return <UniversityFinance UniversityId={UID.UniversityUID}/>;
      case "Gallery":
        return <UniversityGallery UniversityId={UID.UniversityUID}/>;
      case "Glance":
        return <UniversityGlance UniversityId={UID.UniversityUID}/>;
      case "Requirements":
        return <UniversityRequirements UniversityId={UID.UniversityUID}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="information-tabs" style={{ width: "70vw" }}>
        <ol className="detail-tabs" style={tabStyle}>
          <div className={active === "Core" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Core")}>
              Core
            </li>
            <hr />
          </div>
          <div className={active === "Courses" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Courses")}>
              Courses
            </li>
            <hr />
          </div>
          <div className={active === "Finance" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Finance")}>
              Finance
            </li>
            <hr />
          </div>
          <div className={active === "Gallery" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Gallery")}>
              Gallery
            </li>
            <hr />
          </div>
          <div className={active === "Glance" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Glance")}>
              Glance
            </li>
            <hr />
          </div>
          <div
            className={active === "Requirements" ? "active" : "tab-container"}
          >
            <li style={itemStyle} onClick={() => setActive("Requirements")}>
              Requirements
            </li>
            <hr />
          </div>
        </ol>
      </div>
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default UniversityDashboard;
