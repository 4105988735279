import React from "react";

const Terms = () => {
  return (
    <div>
      <h1 className="footer-heading">Terms and Conditions</h1>
      <p className="intro-text">
        Please read these terms and conditions carefully before using Our
        Service.
      </p>
    </div>
  );
};

export default Terms;
