import React from "react";
import { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import { AiOutlinePlus } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
const UniversityFinance = (UniversityId) => {
  const [loading, setLoading] = useState(false);
  console.log(UniversityId.UniversityId);
  const [submitted, setSubmitted] = useState(true);
  const [formData, setFormData] = useState([]);
  const addNewForm = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        historyId: 0,
        userId: UniversityId.UniversityId,
        name: "",
        tution: 0,
        accomodation: 0,
        application: 0,
        total: 0,
      },
    ]);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const getResponse = await axiosInstance.get(
          `/api/FinanceModels/FinanceList/${UniversityId.UniversityId}`
        );
        console.log(getResponse);
        setFormData(getResponse.data);
      } catch (error) {
        console.log(error);
        console.log("Error during fetch");
      }
    };
    setSubmitted(false);
    setLoading(false);
    if (submitted) {
      fetchData();
    }
  }, [submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("Updated FormData:", formData);
      const promises = formData.map(async (form) => {
        const formDataToSend = {
          historyId: form.historyId,
          userId: form.userId,
          name: form.name,
          tution: form.tution,
          accomodation: form.accomodation,
          application: form.application,
          total: form.total,
        };

        console.log("FormData to Send:", formDataToSend);

        const response = await axiosInstance.put(
          `/api/FinanceModels/${form.historyId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        setLoading(false);
      });

      await Promise.all(promises);
      toast.success("Data Updated");
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitted(true);
  };

  const handleChange = (index, fieldName, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [fieldName]: value,
    };
    setFormData(updatedFormData);
  };

  const deleteForm = async (index, event) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    const deletedForm = formData[index];

    if (deletedForm && deletedForm.historyId) {
      try {
        setLoading(true);
        const response = await axiosInstance.delete(
          `/api/FinanceModels/${deletedForm.historyId}`
        );

        console.log(response.data);
        // Remove the deleted form from the state
        const updatedForms = [...formData];
        updatedForms.splice(index, 1);
        setFormData(updatedForms);
        toast.success("Form Deleted");
        setLoading(false);
      } catch (error) {
        console.error("Delete Error:", error);
        toast.error("Failed to delete form");
        setLoading(false);
      }
    } else {
      const updatedForms = [...formData];
      updatedForms.splice(index, 1);
      setFormData(updatedForms);
    }
    setLoading(false);
    setSubmitted(true);
  };

  return (
    <>
      <ToastContainer />
      {formData.map((form, index) => (
        <form key={index} action="" className="detail-wrapper">
          <div className="input-area">
            <div className="input-fields">
              <label>Name</label>
              <input
                type="text"
                placeholder="Please enter your full name"
                value={form.name}
                onChange={(e) => handleChange(index, "name", e.target.value)}
              />
            </div>

            <div className="input-fields">
              <label htmlFor="">Tution</label>
              <input
                type="text"
                placeholder="Tuition"
                value={form.tution}
                onChange={(e) => handleChange(index, "tution", e.target.value)}
              />
            </div>
          </div>
          <div className="input-area">
            <div className="input-fields">
              <label htmlFor="" className="form-label">
                Accomodation
              </label>
              <input
                type="text"
                placeholder="Accomodation"
                value={form.accomodation}
                onChange={(e) =>
                  handleChange(index, "accomodation", e.target.value)
                }
              />
            </div>

            <div className="input-fields">
              <label htmlFor="" className="form-label">
                Application
              </label>
              <input
                type="text"
                placeholder="Application"
                value={form.application}
                onChange={(e) =>
                  handleChange(index, "application", e.target.value)
                }
              />
            </div>
          </div>
          <div
            className="btn-field"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            {index === formData.length - 1 && (
              <button className="primary-button" onClick={addNewForm}>
                <AiOutlinePlus size={15} className="sign" />
                Add new info
              </button>
            )}
            {index !== 0 && (
              <button
                className="secondary-button"
                onClick={(event) => deleteForm(index, event)}
              >
                Delete
              </button>
            )}
          </div>
          {loading && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>Loading.....</p>
            </div>
          )}
        </form>
      ))}
      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
    </>
  );
};

export default UniversityFinance;
