import React, { useState } from "react";
import "./Profile/Profile.css";
import { useAuth } from "../../contexts/Auth/AuthContext";
import CouncelorAddStudent from "./Settings/CouncelorAddStudent";
import StudentList from "./Settings/StudentList";
import ProfileBar from "../ProfileBar";
import StudentDashboardList from "./Dashboard/StudentDashboardList";

const AddStudents = () => {
  const currentUser = useAuth();
  const [view, setView] = useState("list");
  const currentUserId = currentUser.currentUser.userId;
  const [userID, setUserID] = useState("");
  const handleEditClick = async (studentUid, type) => {
    if (type === "edit") {
      setView("edit");
    } else {
      setView("view");
    }
    setUserID(studentUid);
  };

  return (
    <div>
      <button className="primary-button" onClick={() => setView("add")}>
        Add Student
      </button>
      {view === "list" && <StudentList onEditClick={handleEditClick} />}
      {view === "add" && <CouncelorAddStudent CounselorId={currentUserId} />}
      {view === "edit" && <ProfileBar StudentUID={userID} />}
      {view === "view" && <StudentDashboardList StudentUID={userID} />}
    </div>
  );
};

export default AddStudents;
