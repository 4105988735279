import React from 'react';
import './UniversityCard.css';
import { useNavigate } from 'react-router-dom';
import backupImg from '../../components/assets/images/collegeback.png';

const UniversityCard = ({ item }) => {
  const navigate = useNavigate();
  const handleUniversityClick = () => {
    const formattedName = item?.slug
      ? item.slug
      : item.name.replace(/\s+/g, '-');
    navigate(`/university/detail/${formattedName}`, {
      state: {
        university: item,
      },
    });
  };

  return (
    <div className="universityCard" onClick={handleUniversityClick}>
      {item.profileImage ? (
        <img
          className="universityCardImage"
          src={item.profileImage}
          alt="uni"
        />
      ) : (
        <img className="universityCardImage" src={backupImg} alt="uni" />
      )}
      <h3 className="universityCardName">{item.name}</h3>
    </div>
  );
};

export default UniversityCard;
