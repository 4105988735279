import React from 'react';
import { MdDelete } from 'react-icons/md';
import demoImage from '../assets/images/imageIcon.png';
import demoPdf from '../assets/images/pdfFileIcon.png';

const DocumentPreview = ({ previews, lists, onDelete, fileType, onRemove }) => {
  // const handleDownload = (url) => async() => {
  //   try {
  //     const response = await fetch(`/api/images/retrieve/${imageName}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     const data = await response.blob();
  //     const url = window.URL.createObjectURL(new Blob([data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', imageName);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="uploaded-documents">
      {previews &&
        previews.map((preview, index) => (
          <div key={index} className="document-preview">
            <img
              src={preview.type === 'application/pdf' ? demoPdf : preview.url}
              alt="Preview"
            />
            <button
              onClick={() => onRemove(fileType, index)}
              className="delete-button"
            >
              <MdDelete size={14} />
              Remove
            </button>
          </div>
        ))}
      {lists &&
        lists.map((preview, index) => (
          <div
            // onClick={handleDownload(preview.itemLink)}
            key={index}
            className="document"
          >
            <img
              src={preview.itemLink.endsWith('.pdf') ? demoPdf : demoImage}
              alt="Preview"
            />
            <button
              onClick={() => onDelete(preview.id, fileType)}
              type="button"
              className="delete-icon"
            >
              <MdDelete size={14} />
            </button>
          </div>
        ))}
    </div>
  );
};

export default DocumentPreview;
