import React, { useState } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../../../components/axios";

import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../contexts/Auth/AuthContext";
import { toast } from "react-toastify";

const CouncelorAddStudent = () => {
  const UserIdCookie = Cookies.get("userId");
  const currentUser = useAuth();
  const [formData, setFormData] = useState({
    UserId: UserIdCookie,
    email: "",
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Gender: "",
    DateOfBirth: "",
    BirthPlace: "",
    ImageName: "",
    ImageFile: null,
  });

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      ImageFile: e.target.files[0], // Update the property name to ImageFile
    });
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("UserID", "");
    form.append("email", formData.email);
    form.append("firstName", formData.FirstName);
    form.append("lastName", formData.LastName);
    form.append("phoneNumber", formData.PhoneNumber);
    form.append("gender", formData.Gender);
    form.append("dateOfBirth", formData.DateOfBirth);
    form.append("birthPlace", formData.BirthPlace);
    form.append("imageName", formData.ImageName);
    form.append("imageFile", formData.ImageFile);

    if (currentUser) {
      setLoading(true);
      console.log("New User");
      console.log(UserIdCookie);
      console.log(form);
      try {
        await axiosInstance.post(
          `/api/PersonalInformations/Counselor?counselorUID=${UserIdCookie}`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type
            },
          }
        );
        toast.success("Student added successfully");
        setLoading(false);
      } catch (error) {
        toast.error("Failed to add student");
        console.log("Error occured", error.message);
        setLoading(false);
      }
    }
  };
  return (
    <form class="detail-wrapper" style={{ width: "60vw" }}>
      <div
        className="profile-photo"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
          {" "}
          Setup your Personal Details
        </h3>
        <p style={{ margin: "5px 0px 5px 0px" }}>Your Profile Picture</p>
        <div
          className="upload-photo"
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            border: "1px dashed #aaa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPhotoFilm}
              style={{ fontSize: "50px", color: "#aaa" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100px",
              height: "100px",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </div>
        <hr />
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>First Name</label>
          <input
            type="text"
            placeholder="Please enter your first name"
            value={formData.FirstName}
            onChange={(e) => handleChange(e, "FirstName")}
          />
        </div>
        <div className="input-fields">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Please enter your last name"
            value={formData.LastName}
            onChange={(e) => handleChange(e, "LastName")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Email</label>
          <input
            type="text"
            placeholder="Please enter your email"
            value={formData.email}
            onChange={(e) => handleChange(e, "email")}
          />
        </div>
        <div className="input-fields">
          <label>Phone number</label>
          <input
            type="text"
            placeholder="+1 |   Please enter your phone number"
            value={formData.PhoneNumber}
            onChange={(e) => handleChange(e, "PhoneNumber")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            value={formData.Gender}
            onChange={(e) => handleChange(e, "Gender")}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="input-fields">
          <label>DOB</label>
          <input
            type="date"
            className="form-input"
            value={formData.DateOfBirth}
            onChange={(e) => handleChange(e, "DateOfBirth")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label>Place of Birth</label>
          <input
            type="text"
            placeholder="Please enter your Birthplace"
            value={formData.BirthPlace}
            onChange={(e) => handleChange(e, "BirthPlace")}
          />
        </div>
      </div>

      <div
        className="btn-field"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginTop: "20px",
          borderRadius: "1rem",
        }}
      >
        <button className="secondary-button">Reset</button>
        <button className="primary-button" onClick={submit}>
          Add student
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
};

export default CouncelorAddStudent;
