import React, { useState } from 'react';
import './CreateBlog.css';
import { MdDelete } from 'react-icons/md';
import { FaImage } from 'react-icons/fa';
import { toast } from 'react-toastify';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error('Title is required');
    }
    if (!description) {
      return toast.error('Description is required');
    }
    if (!image || !imagePreview) {
      return toast.error('Feature Image is required');
    }
    const newBlog = {
      title,
      description,
      imageLink: imagePreview,
    };
    try {
      setLoading(true);
      const response = await fetch('api/BlogsModels', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBlog),
      });
      console.log(response);
      if (response.ok) {
        setLoading(false);
        toast.success('Blog created successfully');

        setTitle('');
        setDescription('');
        setImage(null);
        setImagePreview('');
      } else {
        const data = await response.json();
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="create-blog-container">
        <h2>
          Write a new{' '}
          <span style={{ color: 'var(--darkblue-color)' }}>blog</span>
          {` `}post
        </h2>
        <form onSubmit={handleSubmit} className="blog-form">
          <div className="form-group">
            <input
              type="text"
              id="title"
              value={title}
              placeholder={'Your Blog Title here...'}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              id="description"
              value={description}
              placeholder="Write your blog post here..."
              cols={30}
              rows={20}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            {imagePreview ? (
              <>
                <h2>
                  Selected feature{' '}
                  <span style={{ color: 'var(--darkblue-color)' }}>Image</span>
                </h2>

                <div className="image-preview">
                  <div
                    onClick={() => {
                      setImage(null);
                      setImagePreview('');
                    }}
                    className="reject-cross"
                  >
                    <MdDelete />
                  </div>
                  <img src={imagePreview} alt="Selected" />
                </div>
              </>
            ) : (
              <>
                <label className="secondary-button image-input" htmlFor="image">
                  <FaImage size={24} />
                  Upload Image
                </label>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </>
            )}
          </div>
          <button
            type="submit"
            style={{ width: 'fit-content' }}
            className="primary-button"
          >
            Create Blog
          </button>
        </form>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>
            <strong>Creating blog...</strong>
          </p>
        </div>
      )}
    </>
  );
};

export default CreateBlog;
