import React, { useState } from "react";
import "./ListTable.css";
import ProfileBar from "../../sections/ProfileBar";
import Councelor from "../../sections/LoggedInPages/Councelor/CouncelorDashboard";
import UniversityDashboard from "../../sections/UniversityDetailsPage/UniversityDashboard/UniversityDashboard";
import { StudentList } from "../../pages";

const ListTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState("");
  const [userID, setUserID] = useState(null);
  const itemsPerPage = 8;

  // Pagination calculation
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props.items.slice(indexOfFirstItem, indexOfLastItem);
  console.log(props);
  const totalPages = Math.ceil(props.items.length / itemsPerPage);

  const handleEdit = (userID) => {
    setView("edit");
    setUserID(userID);
    console.log("Edit clicked user ID", userID);
  };
  const handleView = async (studentUid) => {
   
      setView("view");
      console.log(studentUid)
    setUserID(studentUid);
  };

  const handleDelete = () => {
    console.log("Delete");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {props.role === "Student" && view === "edit" && (
        <ProfileBar StudentUID={userID} />
      )}
      {props.role === "Counselor" && view === "edit" && (
        <Councelor CounselorUID={userID} />
      )}
      {props.role === "University" && view === "edit" && (
        <UniversityDashboard UniversityUID={userID} />
      )}
      {view === "view" && <StudentList onEditClick={handleView}/>}

      {view === "" &&  (
        <div className="table-wrapper">
          {props.items.length === 0 ? (
            <p>No items available</p>
          ) : (
            <>
              <table className="listTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Actions</th>
                    {props.role === "Counselor" && <th>Students</th>}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.userName}</td>
                      <td className="dropdown">
                        <span className="dropbtn">Action</span>
                        <div className="dropdown-content">
                          <span onClick={() => handleEdit(item.id)}>Edit</span>
                          <span onClick={() => handleDelete(item.id)}>Delete</span>
                        </div>
                      </td>
                      {props.role === "Counselor" && (
                        <td>
                          <button
                            className="primary-button"
                            onClick={() => handleView(item.id)}
                          >
                            View Students
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <button
                    key={pageIndex}
                    onClick={() => handlePageChange(pageIndex + 1)}
                    className={currentPage === pageIndex + 1 ? "active" : ""}
                  >
                    {pageIndex + 1}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ListTable;
