import React from "react";
import Logo from "../assets/images/main_logo.png";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container width-1256">
        <div className="footer-section">
          <a href="/">
            <img className="footer-logo" src={Logo} alt="main logo" />
          </a>
          <p className="footer-slogan">Your bridge to boundless education.</p>
          <p>Follow us on</p>
          <div className="social-links">
            <a href="/" className="footer-link">
              <FaFacebook size={32} />
            </a>
            <a href="/" className="footer-link">
              <FaInstagramSquare size={32} />
            </a>
            <a href="/" className="footer-link">
              <FaLinkedin size={32} />
            </a>
          </div>
        </div>
        <div className="footer-sections">
          <div className="footer-section">
            <h2 className="link-title">About</h2>
            <ul className="footer-links">
              <li>
                <a className="footer-link" href="/">
                  Company
                </a>
              </li>
              <li>
                <a className="footer-link" href="/career">
                  Career
                </a>
              </li>
              <li>
                <a className="footer-link" href="/contact">
                  Contact us
                </a>
              </li>
              <li>
                <a className="footer-link" href="/terms-conditions">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a className="footer-link" href="/disclaimer">
                  Disclaimer
                </a>
              </li>
              <li>
                <a className="footer-link" href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h2 className="link-title">Services</h2>
            <ul className="footer-links">
              <li>
                <a className="footer-link" href="/student">
                  Students
                </a>
              </li>
              <li>
                <a className="footer-link" href="/recruitment-partner">
                  Counselors
                </a>
              </li>
              <li>
                <a className="footer-link" href="/institution">
                  Institutions
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h2 className="link-title">Resources</h2>
            <ul className="footer-links">
              <li>
                <a className="footer-link" href="/blog">
                  Blogs
                </a>
              </li>
              <li>
                <a className="footer-link" href="/support">
                  Expert Advise
                </a>
              </li>
              <li>
                <a className="footer-link" href="/">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h2 className="link-title">Get in Touch</h2>
            <ul className="footer-links">
              <a className="footer-link" href="tel:+12345678">
                +123456789
              </a>
              <a className="footer-link" href="mailto:abc@gmail.com">
                abc@gmail.com
              </a>
              <li className="footer-link">Location / Address </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-text">
          Right Trail 2024 &copy; All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
