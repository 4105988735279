import React, { useState } from 'react';
import { useFormik } from 'formik';
import './InqueryPage.css';
import { axiosInstance } from '../../api';
import { toast } from 'react-toastify';

const InqueryPage = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      message: '',
    },
    validate: (values) => {
      const errors = {};

      if (!values.firstName) {
        toast.username = 'First is required.';
      }

      if (!values.email) {
        toast.email = 'Email is required.';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        toast.email = 'Invalid email address.';
      }

      if (!values.message) {
        toast.password = 'Message is required.';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axiosInstance.post(`/api/InquiryForms/`, values);
        if (response.status === 200) {
          setLoading(false);
          toast.success('Message Sent Successfully');
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          'There was an error submitting the form, please try again later'
        );
        console.error('There was an error submitting the form', error);
      }
    },
  });

  return (
    <>
      <section className="inquiry-wrapper">
        <div className="width-1256 inquiry-container">
          <div className="left-section">
            <h2 className="welcome-text">
              We're excited to assist you on your path to discovery
              {` `}
              <span
                style={{ color: 'var(--darkblue-color)', fontWeight: '700' }}
              >
                Welcome aboard!
              </span>
            </h2>
            <p>
              Right Trails offers students great professional application
              advice, recommendations, and simple walkthroughs via a variety of
              channels, including online chat, one-on-one calls, and seminars
              via Google Meet and Zoom.
            </p>
          </div>
          <div className="right-section">
            <h2 className="welcome-text">
              Right Trails {` `}
              <span
                style={{ color: 'var(--darkblue-color)', fontWeight: '700' }}
              >
                Inquiry Support Form
              </span>
            </h2>
            <form className="inquiry-form" onSubmit={formik.handleSubmit}>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
                required
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <label htmlFor="contactNumber">Phone</label>
              <input
                id="contactNumber"
                name="contactNumber"
                type="text"
                placeholder="Contact Number"
                onChange={formik.handleChange}
                value={formik.values.contactNumber}
              />
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows={8}
                placeholder="Message"
                required
                onChange={formik.handleChange}
                value={formik.values.message}
              ></textarea>
              <input
                className="primary-button"
                type="submit"
                value="Submit"
              ></input>
            </form>
          </div>
        </div>
      </section>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>
            <strong>Sending Message..</strong>
          </p>
        </div>
      )}
    </>
  );
};

export default InqueryPage;
