import React from 'react';
import './StatsCounter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faBriefcase,
  faAward,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import { useIntersectionObserver } from '../../../hooks';

const StatsCounter = () => {
  const cardDetails = [
    {
      icon: faCalendar,
      count: 7,
      title: 'Years in Business',
    },
    {
      icon: faBriefcase,
      count: 970,
      title: 'Students Enrolled',
    },
    {
      icon: faAward,
      count: 90,
      title: 'Universities',
    },
    {
      icon: faUsers,
      count: 14,
      title: 'Counselors',
    },
  ];
  const [ref, hasAnimated] = useIntersectionObserver({
    threshold: 0.5,
  });

  const renderCard = cardDetails.map((card, index) => {
    return (
      <div className="countCard" key={index}>
        <FontAwesomeIcon icon={card.icon} size="2x" color="#3255D9" />
        <div className="countContent">
          <h3>
            {hasAnimated ? (
              <CountUp start={0} end={card.count} duration={3} suffix={'+'} />
            ) : (
              '0'
            )}
          </h3>
          <p>{card.title}</p>
        </div>
      </div>
    );
  });

  return (
    <section className="countContainer" ref={ref}>
      <div className="countCards width-1256">{renderCard}</div>
    </section>
  );
};

export default StatsCounter;
