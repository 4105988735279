import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryList from "react-select-country-list";
import Select from "react-select";

const UniversityCore = (UniversityId) => {
  const countries = CountryList().getData();
  const [notFound, setNotFound] = useState(0);
  const universityUid = Cookies.get("userId");
  console.log(universityUid);

  const [formData, setFormData] = useState({
    UserId: UniversityId.UniversityId,
    Name: "",
    Type: "",
    TotalStudents: "",
    About: "",
    Country: "",
    State: "",
    Latitute: "",
    Longitude: "",
    Facebook: "",
    Twitter: "",
    LinkedIn: "",
    CoverImage: "",
    CoverFile: null,
    EstablishedDate: "",
    ProfileImage: "",
    ProfileFile: null,
  });

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setFormData({
      ...formData,
      [fieldName]: newValue,
    });
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    if (type === "profile") {
      setFormData({
        ...formData,
        ProfileFile: file,
      });
      const imageUrl = URL.createObjectURL(file);
      setSelectedProfileImage(imageUrl);
    } else if (type === "cover") {
      setFormData({
        ...formData,
        CoverFile: file,
      });
      const imageUrl = URL.createObjectURL(file);
      setSelectedCoverImage(imageUrl);
    }
  };

  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("UserID", UniversityId.UniversityId);
    form.append("Name", formData.Name);
    form.append("Type", formData.Type);
    form.append("TotalStudents", formData.TotalStudents);
    form.append("About", formData.About);
    form.append("Country", formData.Country);
    form.append("State", formData.State);
    form.append("Latitute", formData.Latitute);
    form.append("ProfileImage", formData.ProfileImage);
    form.append("ProfileFile", formData.ProfileFile);
    form.append("Longitude", formData.Longitude);
    form.append("Facebook", formData.Facebook);
    form.append("Twitter", formData.Twitter);
    form.append("LinkedIn", formData.LinkedIn);
    form.append("CoverImage", formData.CoverImage);
    form.append("CoverFile", formData.CoverFile);
    form.append("EstablishedDate", formData.EstablishedDate);
    console.log(notFound);

    if (notFound === 1) {
      setLoading(true);
      console.log("New User");
      try {
        await axiosInstance.post(`/api/CoreModels/`, form, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type
          },
        });
        setLoading(false);
      } catch (error) {
        console.log("Error occured", error.message);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        printFormData(form);
        console.log("Inside Put");

        const putResponse = await axiosInstance.put(
          `/api/CoreModels/${UniversityId.UniversityId}`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type
            },
          }
        );
        if (putResponse.status === 204) {
          setLoading(false);
          console.log(putResponse);
          // Success

          toast.success("Data updated ");
        } else {
          setLoading(false);
          console.log(putResponse.status);
          // Display an error message

          toast.error("Failed to update ");
        }
      } catch (error) {
        console.log("Error in Put ", error.message);
      }
    }
    function printFormData(form) {
      for (const [key, value] of form.entries()) {
        console.log(`${key}: ${value}`);
      }
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const getResponse = await axiosInstance.get(
          `/api/CoreModels/${UniversityId.UniversityId}`
        );
        console.log("Profile details for university", getResponse);
        const establishedDate = new Date(getResponse.data.establishedDate);
        setFormData({
          Name: getResponse.data.name,
          Type: getResponse.data.type,
          TotalStudents: getResponse.data.totalStudents,
          About: getResponse.data.about,
          Country: getResponse.data.country,
          State: getResponse.data.state,
          EstablishedDate: establishedDate.toISOString().split("T")[0],
          Latitute: getResponse.data.latitute,
          Longitude: getResponse.data.longitude,
          Facebook: getResponse.data.facebook,
          Twitter: getResponse.data.twitter,
          LinkedIn: getResponse.data.linkedIn,
          ProfileImage: getResponse.data.ProfileImage,
          email: getResponse.data.email,
        });
        setSelectedProfileImage(getResponse.data.profileImage);
        setSelectedCoverImage(getResponse.data.coverImage);

        setLoading(false);
      } catch (error) {
        console.log("catch statement");
        console.log(error);
        setLoading(false);
        if (error.response.status === 404) {
          console.log("INSIDE CATCH ERROR OF IF");
          setNotFound(1);
        } else {
          console.log("Gone in else");
          setNotFound(0);
        }
      }
    };

    fetchProfileDetails();
  }, [UniversityId.UniversityId]);
  return (
    <div>
      <form class="detail-wrapper">
        <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
          Setup Personal Details
        </h3>
        <div className="upload-coverphoto">
          {selectedProfileImage ? (
            <img
              src={selectedProfileImage}
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                zIndex: "100",
                borderRadius: "50%",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPhotoFilm}
              style={{ fontSize: "50px", color: "#aaa" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "profile")}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
              zIndex: "100",
            }}
          />
        </div>
        <hr />
        <div className="cover-image">
          <div>
            {selectedCoverImage ? (
              <img src={selectedCoverImage} alt="Selected" />
            ) : (
              <FontAwesomeIcon
                icon={faPhotoFilm}
                style={{ fontSize: "50px", color: "#aaa" }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, "cover")}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                zIndex: "100",
              }}
            />
          </div>
        </div>

        <div className="input-area">
          <div className="input-fields">
            <label>Name</label>
            <input
              type="text"
              placeholder="Please enter university name"
              value={formData.Name}
              onChange={(e) => handleChange(e, "Name")}
            />
          </div>
          <div className="input-fields">
            <label>Type</label>
            <input
              type="text"
              placeholder="Please enter university Type"
              value={formData.Type}
              onChange={(e) => handleChange(e, "Type")}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="input-fields">
            <label>Total Students</label>
            <input
              type="text"
              placeholder="Please enter total Students"
              value={formData.TotalStudents}
              onChange={(e) => handleChange(e, "TotalStudents")}
            />
          </div>
          <div className="input-fields">
            <label>About</label>
            <input
              type="text"
              placeholder="About your University"
              value={formData.About}
              onChange={(e) => handleChange(e, "About")}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="input-fields">
            <label htmlFor="">Country</label>
            <Select
              id="country"
              name="country"
              options={countries}
              placeholder="Select a country"
              value={
                formData.Country
                  ? { label: formData.Country, value: formData.Country }
                  : null
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "Country")
              }
              classNamePrefix="custom-select"
            />
          </div>
          <div className="input-fields">
            <label>State</label>
            <input
              type="text"
              placeholder="Enter your state"
              value={formData.State}
              onChange={(e) => handleChange(e, "State")}
            />
          </div>
        </div>

        <div className="input-area">
          <div className="input-fields">
            <label>Latitude</label>
            <input
              type="text"
              placeholder="Please enter your latitude"
              value={formData.Latitute}
              onChange={(e) => handleChange(e, "Latitute")}
            />
          </div>
          <div className="input-fields">
            <label>Longitude</label>
            <input
              type="text"
              placeholder="Please enter your Longitude"
              value={formData.Longitude}
              onChange={(e) => handleChange(e, "Longitude")}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="input-fields">
            <label>Facebook</label>
            <input
              type="text"
              placeholder="Please enter your facebook"
              value={formData.Facebook}
              onChange={(e) => handleChange(e, "Facebook")}
            />
          </div>
          <div className="input-fields">
            <label>Twitter</label>
            <input
              type="text"
              placeholder="Please enter your Twitter"
              value={formData.Twitter}
              onChange={(e) => handleChange(e, "Twitter")}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="input-fields">
            <label>linkedIn</label>
            <input
              type="text"
              placeholder="Please enter your LinkedIn"
              value={formData.LinkedIn}
              onChange={(e) => handleChange(e, "LinkedIn")}
            />
          </div>
          <div className="input-fields">
            <label>Established Date</label>
            <input
              type="date"
              className="form-input"
              value={formData.EstablishedDate}
              onChange={(e) => handleChange(e, "EstablishedDate")}
            />
          </div>
        </div>
        <div className="btn-field">
          <button className="primary-button" onClick={submit}>
            Update profile
          </button>
        </div>
        {loading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Loading.....</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default UniversityCore;
