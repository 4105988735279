import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/Auth/AuthContext";
import "./Dashboard.css";
import { DashBoardCard } from "../../../components";
import { axiosInstance } from "../../../api";
import StudentDashboardList from "./StudentDashboardList";
import AddStudents from "../AddStudents";
import Cookies from "js-cookie";
import StudentListApplied from "../../UniversityDetailsPage/UniversityDashboard/StudentListApplied";
const Dashboard = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [uniCount, setUniCount] = useState(0);
  const [stdCount, setStdCount] = useState(0);
  const [counCount, setCounCount] = useState(0);
  const [roleCounts, setRoleCounts] = useState([]);
  const isUserAdmin = currentUser.userRole === "Admin";
  const isUser = currentUser.userRole;

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        setLoading(true);
        const roleCounts = await axiosInstance.get(
          `/api/Authentication/roleCounts`
        );
        setRoleCounts(roleCounts.data);
        console.log(roleCounts.data);
        setLoading(false);
        setCounCount(roleCounts.data[0].userCount);
        setUniCount(roleCounts.data[2].userCount);
        setStdCount(roleCounts.data[3].userCount);
      } catch (error) {
        console.log(error);
      }
    };
    setLoading(false);

    fetchUniversities();
  }, [isUserAdmin]);
  return (
    <section className="info-wrapper">
      <h2 className="welcome-text">
        Welcome {` `}
        <span style={{ color: "var(--darkblue-color)", fontWeight: "700" }}>
          {currentUser.userRole}
        </span>
      </h2>
      <p>
        Embark on the journey to your dream university, the Right Trial way.
      </p>
      {isUser === "Student" && (
        <StudentDashboardList StudentUID={Cookies.get("userId")} />
      )}
      {isUser === "Counselor" && <AddStudents />}

      {isUser === "University" && <StudentListApplied />}

      {isUserAdmin && (
        <div className="dashboardCards">
          {loading ? (
            <div>Loading ...</div>
          ) : (
            <>
              <DashBoardCard
                linkTo={"/university-list"}
                title={"Universities"}
                count={uniCount}
              />{" "}
              <DashBoardCard
                linkTo={"/student-list"}
                title={"Students"}
                count={stdCount}
              />{" "}
              <DashBoardCard
                linkTo={"/counselor-list"}
                title={"Counselors"}
                count={counCount}
              />
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default Dashboard;
