import React, { useState, useEffect } from "react";
import axiosInstance from "../../../components/axios";
import Cookies from "js-cookie";
const StudentListApplied = () => {
  const [studentData, setStudentData] = useState([]);
  const currentUserId = Cookies.get("userId");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      console.log(currentUserId);
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `api/ApplyModels/University?userId=${currentUserId}`
        );
        setStudentData(response.data);
        setData(true);
        setLoading(false);
        console.log(response);
        console.log(studentData);
      } catch (error) {
        console.log(error);
        setData(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      {data && (
        <div className="table-wrapper">
          <h1 style={{ fontWeight: "500", color: "blue", marginTop: "10px" }}>
            Applied Students
          </h1>
          <table className="listTable">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Applied Program</th>
                <th>Type</th>
                <th>Application Fee</th>
                <th>Accomodation Fee</th>
                <th>Tution Fee</th>
                <th>Total Fee</th>
                <th>Payment Status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {studentData.map((student, index) => (
                <tr key={index}>
                  <td>{student.studentName}</td>
                  <td>{student.appliedProgram}</td>
                  <td>{student.type}</td>
                  <td>{student.application}</td>
                  <td>{student.accomodation}</td>
                  <td>{student.tution}</td>
                  <td>{student.total}</td>
                  <td>{student.paymentStatus}</td>
                  <td>{student.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!data && (
        <div>
          <h3 style={{ textAlign: "center", color: "blue" }}>
            Not Applied Yet
          </h3>
          <emptyAnimation />
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </div>
  );
};

export default StudentListApplied;
