import React, { useState } from "react";
import "./Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgetPasswordForm = () => {
  const location = useLocation();
  const isStudent = location.pathname.includes("student");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const Submit = async (values) => {
    setLoading(true);

    try {
      const response = await fetch(
        `/api/Authentication/forgot-password?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        }
      );

      if (response.ok) {
        setLoading(false);
        toast.success(
          "Resetting successful! Check your email for confirmation."
        );
        navigate(isStudent ? "/member/login" : "/agent/login");
      } else {
        const data = await response.json();
        setLoading(false);
        toast.error(`Resetting failed: ${data.message}`);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred during Resetting.");
      console.error(error);
    }
  };
  return (
    <div className="form-wrapper">
      <h2>Reset Your Password</h2>
      <span>Enter your email below to receive password reset link</span>
      <form className="form-values">
        <div className="input-wrapper">
          <label htmlFor="username">Email</label>
          <div className="input-container">
            <FontAwesomeIcon icon={faUser} className="icon" />
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
              autoComplete="off"
              name="username"
              id="username"
              placeholder="Email"
            />
          </div>
        </div>
        <Button
          style={{ margin: "10px" }}
          className="primary-button "
          onClick={Submit}
        >
          Send Reset Link
        </Button>
      </form>
      <p style={{ marginTop: " 20px" }}>
        {
          <a
            style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
            href={isStudent ? "/member/login" : "/agent/login"}
          >
            Back to Login
          </a>
        }
      </p>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Sending link...</p>
        </div>
      )}
    </div>
  );
};

export default ForgetPasswordForm;
