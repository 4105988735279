import React, { useEffect, useState } from "react";
import "./CourseCard.css";
import { IoMdArrowRoundForward } from "react-icons/io";
import { GiChestnutLeaf } from "react-icons/gi";
import { FaRegSnowflake } from "react-icons/fa";
import { BsFillSunFill } from "react-icons/bs";
import { useAuth } from "../../contexts/Auth/AuthContext";
import { axiosInstance } from "../../api";
import { toast } from "react-toastify";

const CourseCard = ({ items }) => {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isUserCounselor = currentUser?.userRole === "Counselor";

  useEffect(() => {
    if (isUserCounselor) {
      const getStudent = async () => {
        try {
          const response = await axiosInstance.get(
            ` /api/StudentListModels/Counselor?userId=${currentUser.userId}`
          );
          const detailedStudents = await Promise.all(
            response.data.map(async (student) => {
              try {
                const studentInfo = await axiosInstance.get(
                  `/api/PersonalInformations/${student.studentUid}`
                );
                return { ...student, ...studentInfo.data };
              } catch (error) {
                console.error(
                  `Error fetching info for student ${student.studentUid}:`,
                  error
                );
                return student;
              }
            })
          );

          setStudentList(detailedStudents);
          console.log("Detailed student list:", detailedStudents);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getStudent();
    }
  }, [isUserCounselor, currentUser?.userId]);

  const [selectedCourse, setSelectedCourse] = useState({});
  if (items.length === 0) {
    return <div>No courses available</div>;
  }
  const coursesByType = {};

  items.forEach((item) => {
    if (!coursesByType[item.type]) {
      coursesByType[item.type] = [item];
    } else {
      coursesByType[item.type].push(item);
    }
  });

  const handleClick = (course) => {
    setSelectedCourse(course);
    console.log("Selected course:", course);
    setShowModal(true);
  };

  const handleApply = async (event) => {
    event.preventDefault();

    const selectedStudentId =
      currentUser.userRole === "Counselor"
        ? event.target.elements.student.value
        : currentUser.userId;

    try {
      const response = await axiosInstance.get(
        `/api/FinanceModels/FinanceList/${selectedCourse.userId}`
      );
      const financialData = response.data;
      console.log("Financial data:", financialData);

      const filteredFinancialData = financialData.find(
        (data) => data.name.toLowerCase() === selectedCourse.type.toLowerCase()
      );

      if (!filteredFinancialData) {
        throw new Error("No matching financial data found.");
      }

      const { tution, accomodation, application, total } =
        filteredFinancialData;

      const applyData = {
        studentUID: selectedStudentId,
        universityUID: selectedCourse.userId,
        counselorUID:
          currentUser.userRole === "Counselor" ? currentUser.userId : "",
        type: selectedCourse.type,
        appliedProgram: selectedCourse.name,
        tution: tution || 0,
        accomodation: accomodation || 0,
        application: application || 0,
        total: total || 0,
        paymentStatus: "UnPaid",
        status: "Pending",
      };

      console.log("Applying for course:", applyData);
      setLoading(true);

      await axiosInstance.post("/api/ApplyModels/", applyData);
      toast.success("Application submitted successfully");
      setShowModal(false);
      setLoading(false);
    } catch (error) {
      console.error("Error applying for course:", error);

      // Display a user-friendly error message
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        if (error.response.status === 409) {
          toast.warn("Already applied for selected course");
        } else {
          toast.error(
            `Error applying for course: ${
              error.response.data.message || error.message
            }`
          );
        }
      } else if (error.request) {
        console.error("Request data:", error.request);
        toast.error("No response received from server");
      } else {
        console.error("Error message:", error.message);
        toast.error(`Error: ${error.message}`);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {Object.keys(coursesByType).map((type, index) => (
        <div className="course-type-container" key={index}>
          <h3 className="course-type">{type}: </h3>
          {coursesByType[type].map((course, courseIndex) => (
            <div key={courseIndex} className="course-card">
              <div className="course-details">
                <h3>{course.name}</h3>
                <div className="course-detail">
                  <strong>Area of Study:</strong>
                  <p>{course.area}</p>
                </div>
                <div className="course-detail">
                  <strong>Intake:</strong>
                  {course.fall && (
                    <p>
                      <GiChestnutLeaf />
                      Fall (Aug/Sept)
                    </p>
                  )}
                  {course.spring && (
                    <p>
                      <FaRegSnowflake />
                      Spring (Jan/Feb)
                    </p>
                  )}
                  {course.summer && (
                    <p>
                      <BsFillSunFill />
                      Summer (May/June)
                    </p>
                  )}
                </div>
                <p></p>
              </div>
              <button
                onClick={() => handleClick(course)}
                className="secondary-button apply-course"
              >
                Apply <IoMdArrowRoundForward />
              </button>
            </div>
          ))}
        </div>
      ))}
      {showModal && (
        <div className="loading-overlay">
          <div className="modal-container">
            <span
              style={{ cursor: "pointer", fontSize: "32px" }}
              className="close"
              onClick={() => setShowModal(false)}
            >
              &times;
            </span>
            {currentUser ? (
              <div className="modal-content">
                <h2>
                  Applying for{" "}
                  <span style={{ color: "var(--darkblue-color)" }}>
                    {selectedCourse.name}
                  </span>
                </h2>
                <form onSubmit={handleApply}>
                  {currentUser.userRole === "Counselor" && (
                    <>
                      <h2>
                        Refer your{" "}
                        <span style={{ color: "var(--darkblue-color)" }}>
                          Student
                        </span>
                      </h2>
                      <label htmlFor="student">List of your Students</label>
                      <select name="student" id="student" required>
                        <option value="none">
                          {studentList.length > 0
                            ? "-- Select student --"
                            : "-- No Student Available --"}
                        </option>
                        {studentList.map((student, index) => (
                          <option key={index} value={student.userId}>
                            {student.firstName} {student.lastName}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <div className="button-collection">
                    <button
                      type="button"
                      className="secondary-button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button className="primary-button" type="submit">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="modal-content">
                <h2>
                  Do you want to apply for{" "}
                  <span style={{ color: "var(--darkblue-color)" }}>
                    {selectedCourse.name}
                  </span>
                </h2>
                <p>You need to login as a student or as an agent to apply.</p>
                <div className="button-collection">
                  <a
                    href="/member/login"
                    className="primary-button apply-course"
                  >
                    Login as Student <IoMdArrowRoundForward />
                  </a>
                  <a
                    href="/agent/login"
                    className="secondary-button apply-course"
                  >
                    Login as an Agent <IoMdArrowRoundForward />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Applying.....</p>
        </div>
      )}
    </>
  );
};

export default CourseCard;
