import React, { useState } from "react";
import Profile from "./LoggedInPages/Profile/Profile";
import Address from "./LoggedInPages/Address/Address";
import "./LoggedInPages/Profile/Profile.css";
import LanguageScore from "./LoggedInPages/Dashboard/LanguageScore/LanguageScore";
import GPAScore from "./LoggedInPages/GPAScore/GPAScore";
import Academic_Information from "./LoggedInPages/Academic_Information/Academic_Information";
import Contact_Information from "./LoggedInPages/Contact_Information/Contact_Information";
import Documents from "./LoggedInPages/Documents/Documents";
const ProfileBar = (UID) => {
  const [active, setActive] = useState("Personal Information");

  const renderContent = () => {
    switch (active) {
      case "Personal Information":
        return <Profile StudentId={UID.StudentUID} />;
      case "Address":
        return <Address StudentId={UID.StudentUID} />;
      case "Language Score":
        return <LanguageScore StudentId={UID.StudentUID} />;
      case "GPA Score":
        return <GPAScore StudentId={UID.StudentUID} />;
      case "Academic Information":
        return <Academic_Information StudentId={UID.StudentUID} />;
      case "Contact Information":
        return <Contact_Information StudentId={UID.StudentUID} />;
        case 'Documents':
          return <Documents StudentId={UID.StudentUID}/>
      default:
        return null;
    }
  };

  const tabStyle = {
    display: "flex",
    justifyContent: "space-between",
    listStyleType: "none",
    padding: 0,
  };

  const itemStyle = {
    margin: "0 10px",
    cursor: "pointer",
  };

  return (
    
      <div className="information-tabs">
        <ol className="detail-tabs" style={tabStyle}>
          <div
            className={
              active === "Personal Information" ? "active" : "tab-container"
            }
          >
            <li
              style={itemStyle}
              onClick={() => setActive("Personal Information")}
            >
              Personal
            </li>
            <hr />
          </div>
          <div className={active === "Address" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("Address")}>
              Address
            </li>
            <hr />
          </div>
          <div
            className={active === "Language Score" ? "active" : "tab-container"}
          >
            <li style={itemStyle} onClick={() => setActive("Language Score")}>
              Language
            </li>
            <hr />
          </div>
          <div className={active === "GPA Score" ? "active" : "tab-container"}>
            <li style={itemStyle} onClick={() => setActive("GPA Score")}>
              GPA
            </li>
            <hr />
          </div>
          <div
            className={
              active === "Academic Information" ? "active" : "tab-container"
            }
          >
            <li
              style={itemStyle}
              onClick={() => setActive("Academic Information")}
            >
              Academic
            </li>
            <hr />
          </div>
          <div
            className={
              active === "Contact Information" ? "active" : "tab-container"
            }
          >
            <li
              style={itemStyle}
              onClick={() => setActive("Contact Information")}
            >
              Contact
            </li>
            <hr />
          </div>
          <div
            className={
              active === "Documents" ? "active" : "tab-container"
            }
          >
            <li
              style={itemStyle}
              onClick={() => setActive("Documents")}
            >
              Documents
            </li>
            <hr />
          </div>
        </ol>
        <div className="tab-content">{renderContent()}</div>
      </div>
      
    
  );
};

export default ProfileBar;
