import React from 'react';
import './Layout.css';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Navbar } from '../components';

function Layout() {
  const location = useLocation();
  const isTransparent = location.pathname === '/';

  return (
    <div className="layoutWrapper">
      <Navbar isTransparent={isTransparent} />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
