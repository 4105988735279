import React, { useEffect, useState } from 'react';
import { ListTable } from '../../../../components';
import { useAuth } from '../../../../contexts/Auth/AuthContext';
import { axiosInstance } from '../../../../api';

const StudentList = () => {
  const { currentUser } = useAuth();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const isUserAdmin = currentUser.userRole === 'Admin';

  useEffect(() => {
    const getStudent = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(` /api/Authentication/userlist/student`);
        const detailedStudents = await Promise.all(
          response.data.map(async (student) => {
            try {
              const studentInfo = await axiosInstance.get(
                `/api/PersonalInformations/${student.studentUid}`
              );
              return { ...student, ...studentInfo.data };
            } catch (error) {
              console.error(
                `Error fetching info for student ${student.studentUid}:`,
                error
              );
              return student;
            }
          })
        );

        setStudents(detailedStudents);
        setLoading(false);
        console.log('Detailed student list:', detailedStudents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    getStudent();
  }, [isUserAdmin]);

  return (
    <section className="info-wrapper">
      <h2>
        <span style={{ color: 'var(--darkblue-color)' }}>Student</span>
        {` `}List
      </h2>
      {loading ? <div>Loading ...</div> : <ListTable items={students} role='Student' />}
    </section>
  );
};

export default StudentList;
