import React from 'react';
import './Explore.css';

const Explore = () => {
  return (
    <section className="exploreContainer">
      <div className="exploreContent width-1256">
        <h1>
          Explore studying in Canada or the US <br /> with expert guidance.
        </h1>
        <div className="contactinfo-container">
          <a href="tel:+001 234 56 78">+001 234 56 78</a>
          <a href="/member/register" className="secondary-button">
            Student Registration
          </a>
        </div>
      </div>
    </section>
  );
};

export default Explore;
