import React from 'react';
import './SearchNavigation.css';
import { IoMdArrowRoundForward } from 'react-icons/io';
import coverImage from '../../../components/assets/images/about3.png';

const SearchNavigation = () => {
  return (
    <section className="dark-blue">
      <div className="width-1256 search-navigation-wrapper">
        <div className="left-section">
          <h2 className="page-quote">
            Your{' '}
            <span style={{ color: 'var(--hover-color)' }}>
              Global Opportunity
            </span>
            {'  '}
            is just a click away.
          </h2>
          <p className="page-subquote">
            Find matching programs & top ranking institutions according to your
            background, skills and interest.
          </p>
          <a href="/university/search" className="transparent-button">
            Start Your Search <IoMdArrowRoundForward />
          </a>
        </div>
        <div className="right-section">
          <img
            src={coverImage}
            alt="search-navigation"
            className="search-navigation-image"
          />
        </div>
      </div>
    </section>
  );
};

export default SearchNavigation;
