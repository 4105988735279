import React, { useState } from "react";
import { IoMdArrowRoundForward } from "react-icons/io";
import "./JoinRightTrailsInstitution.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUser,
  faPhone,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import coverImage from "../../../components/assets/images/collegeback.png";
import { axiosInstance } from "../../../api";

const JoinRightTrailsInstitution = () => {
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: 0,
      instituteName: "",
      email: "",
      contactName: "",
      contactNumber: "",
      message: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email is required.";
      }

      if (!values.email) {
        errors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors.email = "Invalid email address.";
      }

      if (!values.contactName) {
        errors.contactName = "Contact Name is required.";
      }
      if (!values.instituteName) {
        errors.instituteName = "Institute Name is required.";
      }
      if (!values.contactNumber) {
        errors.contactNumber = "Contact Number is required.";
      }
      if (!values.message) {
        errors.message = "Message cannot be empty.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
            `/api/UniversityInquiryForms`,
            values,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        // Handle the response as needed
    
        console.log(values);

        if (response.status===200) {
          setLoading(false);
          toast.success(
            "Inquirey sent successfully"
          );
          navigate("/");
        } else {
       
          setLoading(false);
          toast.error(`Failed to send inquiry: `);
          console.log(response)
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred during process.");
        console.error(error);
      }
    },
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <section className="dark-blue">
      <div className="width-1256 search-navigation-wrapper">
        <div className="left-section">
          <h2 className="page-quote">
            Access{" "}
            <span style={{ color: "var(--hover-color)" }}> Global Market</span>
          </h2>
          <p className="page-subquote">
            We are empowering students for their global career dreams, join us
            and enlist your institution.
          </p>
          <button className="transparent-button" onClick={toggleForm}>
            Join Us Today! <IoMdArrowRoundForward />
          </button>
        </div>
        <div className="right-section">
          <img
            src={coverImage}
            alt="search-navigation"
            className="search-navigation-image"
          />
        </div>
      </div>
      {showForm && (
        <div className={`${showForm ? "popup-modal" : ""}`}>
          <div className="form-wrapper popup-form"  style={{width:'40vw'}}>
            <h2 className="welcome-text">
              <span
                style={{ color: "var(--darkblue-color)", fontWeight: "700" }}
              >
                Institute
              </span>{" "}
              Enquiry
            </h2>
            <span>Send your enquiry</span>
            <form className="form-values" onSubmit={formik.handleSubmit}>
              <div className="input-wrapper"  style={{width:'30vw'}}>
                <label htmlFor="instituteName">Institute Name</label>
                <div className="input-container"  style={{width:'40vw'}}>
                  <FontAwesomeIcon icon={faUser} className="icon" />
                  <input
                    type="text"
                    {...formik.getFieldProps("instituteName")}
                    autoComplete="off"
                    placeholder="Institute Name"
                  />
                </div>
                {formik.touched.instituteName &&
                  formik.errors.instituteName && (
                    <div className="error">{formik.errors.instituteName}</div>
                  )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="email">Email</label>
                <div className="input-container">
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <input
                    type="email"
                    {...formik.getFieldProps("email")}
                    autoComplete="off"
                    placeholder="Email"
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="contactName">Contact Name</label>
                <div className="input-container">
                  <FontAwesomeIcon icon={faUser} className="icon" />
                  <input
                    type="text"
                    {...formik.getFieldProps("contactName")}
                    autoComplete="off"
                    placeholder="Contact Name"
                  />
                </div>
                {formik.touched.contactName && formik.errors.contactName && (
                  <div className="error">{formik.errors.contactName}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="contactNumber">Contact Number</label>
                <div className="input-container">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  <input
                    type="text"
                    {...formik.getFieldProps("contactNumber")}
                    autoComplete="off"
                    placeholder="Contact Number"
                  />
                </div>
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <div className="error">{formik.errors.contactNumber}</div>
                  )}
              </div>
              <div className="input-wrapper" style={{height:'80px'}}>
                <label htmlFor="message">Message</label>
                <div className="input-container" style={{height:'70px'}}>
                  <FontAwesomeIcon icon={faMessage} className="icon" />
                  <textarea
                  id="message"
                    style={{ resize: "none", outline: "none", border: "none" , height:'60px'}}
                    type="text"
                    {...formik.getFieldProps("message")}
                    autoComplete="off"
                    placeholder="Enter your message"
                  />
                </div>
                {formik.touched.message && formik.errors.message && (
                  <div className="error">{formik.errors.message}</div>
                )}
              </div>
              <Button
                style={{ margin: "10px" }}
                onClick={toggleForm}
                className="secondary-button "
              >
                Cancel
              </Button>
              <Button style={{ margin: "10px" }} className="primary-button ">
                Submit
              </Button>
            </form>
          </div>
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Sending...</p>
        </div>
      )}
    </section>
  );
};

export default JoinRightTrailsInstitution;
