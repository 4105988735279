import React from 'react';

const UniversityMap = ({ university }) => {
  const { longitude, latitude, name } = university;
  return (
    <section style={{ width: '100%', backgroundColor: '#eaf2fc' }}>
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.073292073073!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6b5b4b7b7b7b%3A0x3e5e6b5b4b7b7b7b!2s${latitude}%2C%20${longitude}!5e0!3m2!1sen!2sng!4v1629780000000!5m2!1sen!2sng`}
        style={{ border: 0, width: '100%', height: '450px', marginTop: '50px' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title={'Location of' + name}
      ></iframe>
    </section>
  );
};

export default UniversityMap;
