import React from 'react';
import { JoinRightTrailsInstitution } from '../../sections';

const InstitutionPage = () => {
  return (
    <>
      <JoinRightTrailsInstitution />
    </>
  );
};

export default InstitutionPage;
