import React, { useState } from "react";
import "./Sidebar.css";
import {
  IoIosDocument,
  IoIosHome,
  IoIosPerson,
  // IoIosPersonAdd,
  IoIosSettings,
} from "react-icons/io";
import { TfiWrite } from "react-icons/tfi";
import {
  FaChalkboardTeacher,
  FaSearchPlus,
  FaUniversity,
} from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { CiLogout, CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth/AuthContext";

const Sidebar = () => {
  const { logout } = useAuth();
  const { currentUser } = useAuth();
  console.log(currentUser.userRole);
  const { navigate } = useNavigate();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);

  const isActive = (path) => {
    return location.pathname === path;
  };
  const Role = currentUser.userRole === "Counselor" ? "agent" : "member";

  const handleLogout = () => {
    logout();
    console.log(Role);
    navigate(`${Role}/login`);
  };
  const sidebarItems = {
    Admin: (
      <>
        <li
          className={`nav-link ${isActive("/university-list") ? "active" : ""}`}
        >
          <FaUniversity size={20} className="icon" />
          <a href="/university-list">University</a>
        </li>
        <li
          className={`nav-link ${
            isActive("/institution-inquiry") ? "active" : ""
          }`}
        >
          <FaSearchPlus size={20} className="icon" />
          <a href="/institution-inquiry"> Institution Inquiry</a>
        </li>
        <li
          className={`nav-link ${isActive("/general-inquiry") ? "active" : ""}`}
        >
          <FaSearchPlus size={20} className="icon" />
          <a href="/general-inquiry"> General Inquiry</a>
        </li>
        <li className={`nav-link ${isActive("/student-list") ? "active" : ""}`}>
          <PiStudent size={20} className="icon" />
          <a href="/student-list">Students</a>
        </li>
        <li
          className={`nav-link ${isActive("/counselor-list") ? "active" : ""}`}
        >
          <FaChalkboardTeacher size={20} className="icon" />
          <a href="/counselor-list">Counselor</a>
        </li>
        <li className={`nav-link ${isActive("/create-blog") ? "active" : ""}`}>
          <TfiWrite size={16} className="icon" />
          <a href="/create-blog">Create blog</a>
        </li>
      </>
    ),
    Student: (
      <>
        <li
          className={`nav-link ${
            isActive("/university/search") ? "active" : ""
          }`}
        >
          <IoIosDocument size={20} className="icon" />
          <a href="/university/search">University</a>
        </li>
        {/* add more if needed */}
      </>
    ),
    Counselor: (
      <>
        <li className={`nav-link ${isActive("/documents") ? "active" : ""}`}>
          <CiSearch size={20} className="icon" />
          <a href="/university/search">Search University</a>
        </li>
        {/* <li className={`nav-link ${isActive("/addStudent") ? "active" : ""}`}>
          <IoIosPersonAdd size={20} className="icon" />
          <a href="/addStudent">Students</a>
        </li> */}
      </>
    ),
  };
  const handleToggle = () => {
    const sidebar = document.querySelector(".aside-sidebar");
    sidebar.classList.toggle("active");
    setShowSidebar(!showSidebar);
  };
  return (
    <>
      <aside className="aside-sidebar">
        <div className="sidebar-wrapper">
          <div className="sidebar-header">
            <h2>Dashboard</h2>
          </div>
          <span
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: "20px",
              border: "1px solid white",
              padding: "4px 12px",
              borderRadius: "50%",
            }}
            onClick={handleToggle}
          >
            X
          </span>
          <nav className="sidebar-nav">
            <ul>
              <li
                className={`nav-link ${isActive("/dashboard") ? "active" : ""}`}
              >
                <IoIosHome size={20} className="icon" />
                <a href="/dashboard">Home</a>
              </li>
              <li
                className={`nav-link ${isActive("/profile") ? "active" : ""}`}
              >
                <IoIosPerson size={20} className="icon" />
                <a href="/profile">Profile</a>
              </li>
              {sidebarItems[currentUser.userRole]}
              <li
                className={`nav-link ${isActive("/settings") ? "active" : ""}`}
              >
                <IoIosSettings size={20} className="icon" />
                <a href="/settings">Settings</a>
              </li>
            </ul>
          </nav>
          <a
            href={`${Role}/login `}
            onClick={handleLogout}
            className="logout-button"
          >
            <CiLogout size={20} className="icon" />
            Logout
          </a>
        </div>
        {/* button to toggle sidebar */}
      </aside>
      {showSidebar && (
        <div onClick={handleToggle} className="sidebar-toggle-btn">
          <IoIosSettings size={20} className="icon" />
        </div>
      )}
    </>
  );
};

export default Sidebar;
