import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AdminAuthLayout, AuthLayout, Layout, LoggedInLayout } from "./layouts";
import {
  AdminLogin,
  AdminRegister,
  BlogDetail,
  BlogPage,
  Career,
  Contact,
  CounselorList,
  CreateBlog,
  Disclaimer,
  GeneralInquiry,
  InqueryPage,
  InstitutionPage,
  LandingPage,
  Privac,
  RecruitmentPartnerPage,
  StudentList,
  StudentPage,
  Terms,
  Universities,
  UniversityDetails,
  UniversityEnquiry,
  UniversityList,
} from './pages';
import ResetPassword from './components/Forms/ResetPasswordForm';
import Confirmation from './pages/Confirmation/Confirmation';
import { ForgetPasswordForm, LoginForm, RegisterForm } from './components';
import { useAuth } from './contexts/Auth/AuthContext';
import {
  // AddStudents,
  Dashboard,
  Documents,
  ProfileWraper,
  Settings,
} from './sections';
import Address from './sections/LoggedInPages/Address/Address';
import LanguageScore from './sections/LoggedInPages/Dashboard/LanguageScore/LanguageScore';
// import Councelor from './sections/LoggedInPages/Councelor/CouncelorDashboard.jsx';
const App = () => {
  const { isLoggedIn } = useAuth();

  const PrivateRoute = ({ children }) => {
    return isLoggedIn ? children : <Navigate to="/member/login" />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/university/search" element={<Universities />} />
          <Route
            path="/university/detail/:name"
            element={<UniversityDetails />}
          />
          <Route path="/student" element={<StudentPage />} />
          <Route
            path="/recruitment-partner"
            element={<RecruitmentPartnerPage />}
          />
          <Route path="/institution" element={<InstitutionPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/detail/:name" element={<BlogDetail />} />
          <Route path="/support" element={<InqueryPage />} />
          <Route path='career' element={<Career/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='terms-conditions' element={<Terms/>}/>
          <Route path='disclaimer' element={<Disclaimer/>}/>
          <Route path='privacy-policy' element={<Privac/>}/>
        </Route>
        <Route path="/member/" element={<AuthLayout name="login" />}>
          <Route path="login" element={<LoginForm role="student" />} />
          <Route path="register" element={<RegisterForm role="student" />} />
          <Route
            path="forget-password"
            element={<ForgetPasswordForm role="student" />}
          />
        </Route>
        <Route path="/agent/" element={<AuthLayout name="register" />}>
          <Route path="login" element={<LoginForm role="counselor" />} />
          <Route
            path="forget-password"
            element={<ForgetPasswordForm role="counselor" />}
          />
          <Route path="register" element={<RegisterForm role="counselor" />} />
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <LoggedInLayout />
            </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          {/* <Route path="addStudent" element={<AddStudents />} /> */}
          <Route path="profile" element={<ProfileWraper />} />
          <Route path="settings" element={<Settings />} />
          <Route path="address" element={<Address />} />
          <Route path="language_score" element={<LanguageScore />} />
          <Route path="documents" element={<Documents />} />
          <Route path="student-list" element={<StudentList />} />
          <Route path="counselor-list" element={<CounselorList />} />
          <Route path="university-list" element={<UniversityList />} />
          <Route path="institution-inquiry" element={<UniversityEnquiry />} />
          <Route path="general-inquiry" element={<GeneralInquiry/>} />
          <Route path="create-blog" element={<CreateBlog />} />
        </Route>
        <Route path="/resetPass" element={<ResetPassword />} />
        <Route path="/confirmation/" element={<Confirmation />} />
        {/* Make a protected route for admin */}
        <Route path="/admin/" element={<AdminAuthLayout />}>
          <Route path="login" index element={<AdminLogin role="admin" />} />
          <Route path="register" element={<AdminRegister role="admin" />} />
        </Route>
        {/* Make a protected route for admin */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
