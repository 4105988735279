import React, { useEffect, useState } from "react";
import CountryList from "react-select-country-list";
import Select from "react-select";
import axiosInstance from "../../../components/axios";
import { toast, ToastContainer } from "react-toastify";

function Address(StudentId) {
  const countries = CountryList().getData();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    UserId: StudentId.StudentId,
    country: "",
    streetName: "",
    city: "",
    province: "",
    zipCode: "",
  });
  const [notFoundFlag, setNotFoundFlag] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/AddressModels/${StudentId.StudentId}`
        );
        console.log(response);
        setFormData({
          country: response.data.country,
          streetName: response.data.streetName,
          city: response.data.city,
          state: response.data.state,
          zipCode: response.data.zipCode,
        });
        setLoading(false);
        setNotFoundFlag(0);
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.status === 404) {
          setNotFoundFlag(1);
        } else {
          setNotFoundFlag(0);
        }
      }
    };

    fetchData();
  }, [StudentId.StudentId]);
  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setFormData({
      ...formData,
      [fieldName]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = {
        userId: StudentId.StudentId,
        country: formData.country,
        streetName: formData.streetName,
        city: formData.city,
        state: formData.state,
        zipCode: formData.zipCode,
      };

      if (notFoundFlag === 1) {
        console.log("DATA IS EMPTY");
        await fetch("/api/AddressModels", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(data),
        });
        toast.success("Data updated successfully");
        console.log("data inserted");
        setLoading(false);
      } else {
        const response = await fetch(
          `/api/AddressModels/${StudentId.StudentId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        console.log("Response:", response);
        if (response.ok) {
          toast.success("Data updated successfully");
          setLoading(false);
        } else {
          toast.error("Failed to update");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update");
      console.error("Error:", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <h3 style={{ textAlign: "center", fontWeight: "500", color: "blue" }}>
        {" "}
        Setup Address Details
      </h3>
      <ToastContainer />
      <div className="input-area">
        <div className="input-fields">
        <label htmlFor="country">Country</label>
        <Select
          id="country"
          name="country"
          options={countries}
          placeholder="Select a country"
          value={
            formData.country
              ? { label: formData.country, value: formData.country }
              : null
          }
          onChange={(selectedOption) => handleChange(selectedOption, "country")}
          classNamePrefix="custom-select"
        />
        </div>
        <div className="input-fields">
          <label htmlFor="province">Province /State</label>
          <input
            type="text"
            id="province"
            name="province"
            placeholder="Province/State"
            value={formData.state}
            onChange={(e) => handleChange(e, "state")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="streetName">Street Name</label>
          <input
            type="text"
            id="streetName"
            name="streetName"
            placeholder="streetName Name."
            value={formData.streetName}
            onChange={(e) => handleChange(e, "streetName")}
          />
        </div>
        <div className="input-fields">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={(e) => handleChange(e, "city")}
          />
        </div>
      </div>
      <div className="input-area">
       
        <div className="input-fields">
          <label htmlFor="zipCode">Zip Code</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            placeholder="Zip Code"
            value={formData.zipCode}
            onChange={(e) => handleChange(e, "zipCode")}
          />
        </div>
      </div>

      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
}

export default Address;
