import React, { useState, useEffect } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import axiosInstance from "../../../components/axios";
import { useAuth } from "../../../contexts/Auth/AuthContext";

const StudentList = ({ onEditClick }) => {
  const currentUser = useAuth();
  const currentUserId = currentUser.currentUser.userId;

  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      console.log(currentUserId);
      try {
        const getResponse = await axiosInstance.get(
          `/api/StudentListModels/Counselor?userId=${currentUserId}`
        );
        setStudentData(getResponse.data);
        console.log(getResponse.data);
      } catch (error) {
        console.log("Catch error statement");
        console.log(error);
      }
    };
    fetchStudents();
  }, [currentUserId]);

  return (
    <div className="table-wrapper">
      <h1 style={{ fontWeight: "500", color: "blue", marginTop: "10px" }}>
        Recent Students
      </h1>
      <table className="listTable">
        <thead>
          <tr>
            <th>Photo</th>
            <th>Full Name/Email</th>
            <th>Phone</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {studentData.map((student, index) => (
            <tr key={index}>
              <td>
                <img
                  src={student.imageUrl}
                  style={{ height: "100px", width: "100px" }}
                  alt="profile"
                />
              </td>
              <td>{student.fullName}</td>
              <td>{student.phoneNumber}</td>
              <td className="dropdown">
                <span className="dropbtn">Action</span>

                <div className="dropdown-content">
                  <span onClick={() => onEditClick(student.studentUid,"view")}>
                    View
                  </span>
                  <span onClick={() => onEditClick(student.studentUid,"edit")}>
                    Edit
                  </span>
                  <span>Delete</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentList;
