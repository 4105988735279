import React from "react";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../../../components/axios";
import { AiOutlinePlus } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

const UniversityCourses = (UniversityId) => {
  const currentUser = Cookies.get("userId");
  const [submitted, setSubmitted] = useState(true);

  const [courseData, setcourseData] = useState([]);
  const addNewForm = () => {
    setcourseData((prevcourseData) => [
      ...prevcourseData,
      {
        historyId: 0,
        userId: UniversityId.UniversityId,
        type: "",
        area: "",
        name: "",
        fall: false,
        summer: false,
        spring: false,
      },
    ]);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const getResponse = await axiosInstance.get(
          `/api/CoursesModels/CourseList/${UniversityId.UniversityId}`
        );
        setcourseData(getResponse.data);
        console.log(getResponse.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error during get:", error.message);
      }
    };
    setSubmitted(false);
    if (submitted) {
      fetchData();
    }
  }, [submitted , UniversityId.UniversityId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Updated FormData:", courseData);
      const promises = courseData.map(async (form) => {
        const formDataToSend = {
          historyId: form.historyId,
          userId: form.userId,
          type: form.type,
          area: form.area,
          name: form.name,
          fall: form.fall,
          summer: form.summer,
          spring: form.spring,
        };

        console.log("FormData to Send:", formDataToSend);

        const response = await axiosInstance.put(
          `/api/CoursesModels/${form.historyId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
      });

      await Promise.all(promises);
      toast.success("Data Updated successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update");
    }
    setSubmitted(true);
  };

  const handleChange = (index, fieldName, value) => {
    const updatedcourseData = [...courseData];
    updatedcourseData[index] = {
      ...updatedcourseData[index],
      [fieldName]: value,
    };
    setcourseData(updatedcourseData);
  };

  const deleteForm = async (index, event) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);

    const deletedForm = courseData[index];

    // Check if the form has a valid historyId before sending the DELETE request
    if (deletedForm && deletedForm.historyId) {
      setLoading(true);
      try {
        const response = await axiosInstance.delete(
          `/api/CoursesModels/${deletedForm.historyId}`
        );

        console.log(response.data);
        // Remove the deleted form from the state
        const updatedForms = [...courseData];
        updatedForms.splice(index, 1);
        setcourseData(updatedForms);

        toast.success("Form Deleted successfully");
        setLoading(false);
      } catch (error) {
        console.error("Delete Error:", error);
        toast.error("Failed to delete form");
        setLoading(false);
      }
    } else {
      const updatedForms = [...courseData];
      updatedForms.splice(index, 1);
      setcourseData(updatedForms);
      setLoading(false);
    }
    setSubmitted(true);
  };
  return (
    <>
      <ToastContainer />
      {courseData.map((form, index) => (
        <form key={index} className="detail-wrapper">
          <div className="input-area">
            <div className="input-fields">
              <label>Type</label>
              <input
                type="text"
                placeholder="Type"
                value={form.type}
                onChange={(e) => handleChange(index, "type", e.target.value)}
              />
            </div>

            <div className="input-fields">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                value={form.name}
                onChange={(e) => handleChange(index, "name", e.target.value)}
              />
            </div>
          </div>
          <div className="input-area">
            <div className="input-fields">
              <label>Area</label>
              <input
                type="text"
                placeholder="Area"
                value={form.area}
                onChange={(e) => handleChange(index, "area", e.target.value)}
              />
            </div>
          </div>

          <div className="check-boxes">
            <div className="checkbox-content">
              <input
                type="checkbox"
                checked={form.fall}
                onChange={(e) => handleChange(index, "fall", e.target.checked)}
              />
              <label>{"  "}Fall</label>
            </div>

            <div className="checkbox-content">
              <input
                type="checkbox"
                checked={form.summer}
                onChange={(e) =>
                  handleChange(index, "summer", e.target.checked)
                }
              />
              <label>{"  "}Summer</label>
            </div>

            <div className="checkbox-content">
              <input
                type="checkbox"
                checked={form.spring}
                onChange={(e) =>
                  handleChange(index, "spring", e.target.checked)
                }
              />
              <label>{"  "}Spring</label>
            </div>
          </div>

          <div
            className="btn-field"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            {index === courseData.length - 1 && (
              <button className="primary-button" onClick={addNewForm}>
                <AiOutlinePlus size={15} className="sign" />
                Add new info
              </button>
            )}
            {index !== 0 && (
              <button
                className="secondary-button"
                onClick={(event) => deleteForm(index, event)}
              >
                Delete
              </button>
            )}
          </div>
          {loading && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>Loading.....</p>
            </div>
          )}
        </form>
      ))}
      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
    </>
  );
};

export default UniversityCourses;
