import React, { useState } from 'react';
import { faEye, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/Auth/AuthContext';

const AdminLogin = ({ role }) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = 'username is required.';
      }

      if (!values.password) {
        errors.password = 'Password is required.';
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (role !== 'admin') {
        return;
      }
      setLoading(true);
      try {
        const response = await login(values);

        if (response.status === 200) {
          setTimeout(() => {
            setLoading(false);
            navigate('/dashboard');
          }, 2000);
        } else {
          const data = response.data;
          setMessage(`Login failed: ${data.message}`);
          setLoading(false);
        }
      } catch (error) {
        setMessage('Invalid details.');
        setLoading(false);
      }
    },
  });
  return (
    <>
      <div className="form-wrapper">
        <h2 className="welcome-text">
          <span style={{ color: 'var(--darkblue-color)', fontWeight: '700' }}>
            Admin
          </span>
          {` `}Login
        </h2>
        <span>Login with Email</span>
        <form onSubmit={formik.handleSubmit} method="get">
          <div className="input-wrapper">
            <label htmlFor="username">Username</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="icon" />
              <input
                type="text"
                value={formik.values.username}
                onChange={formik.handleChange}
                autoComplete="off"
                name="username"
                id="username"
                placeholder="Username"
              />
            </div>
          </div>
          {formik.touched.username && formik.errors.username && (
            <div className="error">{formik.errors.username}</div>
          )}
          <div className="input-wrapper">
            <label htmlFor="password">Password</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="icon" />
              <input
                type={showpassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                autoComplete="off"
                name="password"
                id="password"
                placeholder="Password"
              />
              <FontAwesomeIcon
                icon={faEye}
                className="icon icon-clickable"
                onClick={() => setShowPassword(!showpassword)}
              />
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="error">{formik.errors.password}</div>
          )}

          <p className="forget-password">
            <a href="/admin/forget-password">Forget Password ?</a>
          </p>
          <Button style={{ margin: '10px' }} className="primary-button">
            Login
          </Button>
        </form>

        <p style={{ marginTop: ' 20px' }}>
          Don't have an account?{` `}
          {
            <a
              style={{ color: 'var(--darkblue-color)', fontWeight: '700' }}
              href="/admin/register"
            >
              Register Now
            </a>
          }
        </p>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Logging in.....</p>
        </div>
      )}
    </>
  );
};

export default AdminLogin;
