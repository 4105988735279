import React from "react";

const Career = () => {
  return (
    <div>
      <h1 style={{ color: "blue", textAlign: "center", fontWeight: "500" }}>
        Open Roles
      </h1>
      <p className="width-1256">
        There is currently no open position available
      </p>
    </div>
  );
};

export default Career;
