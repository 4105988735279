import React, { useEffect, useState } from "react";
import axiosInstance from "../../../components/axios";
import { toast } from "react-toastify";
function Contact_Information(StudentId) {
  const [formData, setFormData] = useState({
    userId: StudentId.StudentId,
    contactName: "",
    relationship: "",
    telephoneNumber: "",
    email: "",
  });

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [loading, setLoading] = useState(false);

  const [notfoundFlag, setNotFoundFlag] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Inside Get");
      setLoading(true);

      try {
        const getResponse = await axiosInstance.get(
          `/api/EmergencyContactModels/${StudentId.StudentId}`
        );
        setFormData(getResponse.data);
        setFormData({
          contactName: getResponse.data.contactName,
          relationship: getResponse.data.relationship,
          telephoneNumber: getResponse.data.telephoneNumber,
          email: getResponse.data.email,
        });
        setNotFoundFlag(0);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        if (error.response && error.response.status === 404) {
          setNotFoundFlag(1);
          console.log("here");
        } else {
          setNotFoundFlag(0);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [StudentId.StudentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("userId", StudentId.StudentId);
    form.append("contactName", formData.contactName);
    form.append("relationship", formData.relationship);
    form.append("telephoneNumber", formData.telephoneNumber);
    form.append("email", formData.email);
    if (notfoundFlag === 1) {
      setLoading(true);
      console.log("New User inside Post block");
      try {
        await axiosInstance.post(`/api/EmergencyContactModels`, form, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Data updated successfully");
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to update");
        setLoading(false);
      }
    } else {
      setLoading(true);
      console.log("User existed Inside Put block");
      try {
        const putResponse = await axiosInstance.put(
          `/api/EmergencyContactModels/${StudentId.StudentId}`,
          form,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response is:", putResponse);
        if (putResponse.status === 200) {
          toast.success("Data updated successfully");
          console.log("Data Insert Success");
        } else {
          console.log("Failed to update");
          toast.error("Failed to update");
        }
        setLoading(false);
      } catch (error) {
        toast.error("Failed to update");
        console.log("Error occured", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 style={{ fontWeight: "500", textAlign: "center", color: "blue" }}>
        Emergency Contact Person Information
      </h3>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="name">Contact Name</label>
          <input
            type="text"
            value={formData.contactName}
            onChange={(e) => handleChange(e, "contactName")}
          />
        </div>
        <div className="input-fields">
          <label htmlFor="name">Relationship</label>
          <input
            type="text"
            value={formData.relationship}
            onChange={(e) => handleChange(e, "relationship")}
          />
        </div>
      </div>
      <div className="input-area">
        <div className="input-fields">
          <label htmlFor="name">Telephone Number</label>
          <input
            type="text"
            value={formData.telephoneNumber}
            onChange={(e) => handleChange(e, "telephoneNumber")}
          />
        </div>
        <div className="input-fields">
          <label htmlFor="name">Email</label>
          <input
            type="text"
            value={formData.email}
            onChange={(e) => handleChange(e, "email")}
          />
        </div>
      </div>
      <div className="btn-field">
        <button className="primary-button" onClick={handleSubmit}>
          Update profile
        </button>
      </div>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      )}
    </form>
  );
}

export default Contact_Information;
