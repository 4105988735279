import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";


import logo from "../../components/assets/images/alternative_logo.png";
import Animation from "./Animation";

import "./confirmation.css";
import { Button } from "reactstrap";

const Confirmation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/api/Authentication/ConfirmEmail?token=${token}&email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        toast.success("Confirmation Successful!");

        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        const data = await response.json();

        toast.error(`COnfirmation failed: ${data.message}`);
      }
    } catch (error) {
      toast.error("Unexpected error occured.");
    }
  };
  useEffect(() => {
    submit(); // Call submit function on component mount
  }, []);
  const slicedEmail = email.split("@")[0];
  return (
    <div className="confirmat">
      <ToastContainer />
      <img src={logo} alt="logo" className="logo2"  style={{ filter: 'grayscale(100%)' }}/>
      <div style={{ width: '300px' , marginLeft:'40vw'}}>
      <Animation/>
      </div>
     
      <div className="content">
        <h2 >Hi! {slicedEmail}</h2>
      <h4 style={{fontSize:'larger',  fontWeight:'400',paddingTop:'10px'}}>Welcome to Right Trials</h4>
      <p>Your email has been verified successfully.</p>
       <p className="last"> Thank you for registering with us.</p>
      </div>
      <Button
        href="/"
        style={{  color: "white", fontSize: "large", marginLeft:'47vw' } }
        className='primary-button'>
        Login
      </Button>
    
      
    </div>
  );
};

export default Confirmation;


  
  
