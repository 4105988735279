import React from 'react';
import { VideoParallax } from '../../components';
import './LandingPage.css';
import {
  WelcomeSection,
  UniversityDetails,
  StatsCounter,
  VideoSection,
  Explore,
  ReviewSection,
} from '../../sections';

const LandingPage = () => {
  return (
    <>
      <VideoParallax />
      <WelcomeSection />
      <UniversityDetails />
      <StatsCounter />
      <VideoSection />
      <Explore />
      <ReviewSection />
    </>
  );
};

export default LandingPage;
