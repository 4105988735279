import React from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import { Footer, Sidebar, Navbar } from '../components';

const LoggedInLayout = () => {
  return (
    <div className="layoutWrapper">
      <Navbar />
      <div className="contentWrapper width-1256">
        <Sidebar />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LoggedInLayout;
