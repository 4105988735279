import React from 'react';
import { AboutRightTrails, SearchNavigation } from '../../sections';

const StudentPage = () => {
  return (
    <>
      <SearchNavigation />
      <AboutRightTrails />
    </>
  );
};

export default StudentPage;
